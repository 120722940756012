<template>
  <dialogComp
    :title="titleD"
    :isShow="isShow"
    :modal="false"
    @close="close"
    @sure="sure"
  >
  <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="90px"
    >
    <inputFormComp
      label="注意事项:"
      prop="note"
      :hval="formData.note"
      @blur="noteBlur"
      placeholder="请输入注意事项"
    />
    <!-- <inputFormComp
      inputType="textarea"
      label="备注:"
      prop="remark"
      :hval="formData.remark"
      @blur="remarkBlur"
      placeholder="请输入备注"
    /> -->
  </el-form>
  </dialogComp>
</template>

<script>
import { serviceNoteAdd } from '@/api'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    // 类别，1：上门前（默认），2：上门服务，3：服务完结
    noteType: {
      type: Number,
      default: 1
    },
    visitId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      formData: {
        note: ''
        // remark: ''
      },
      rules: {
        note: [{ required: true, message: '请输入注意事项', trigger: 'blur' }]
      }
    }
  },
  computed: {
    titleD () {
      return this.handleTitle(this.noteType)
    }
  },
  methods: {
    handleTitle (type) {
      switch (type) {
        case 1:
          return '新增上门前注意事项'
        case 2:
          return '新增上门服务注意事项'
        case 3:
          return '新增服务完结注意事项'
      }
    },
    noteBlur (val) {
      this.formData.note = val
    },
    // remarkBlur (val) {
    //   this.formData.remark = val
    // },
    close () {
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const params = {
            ...this.formData,
            visitId: this.visitId,
            type: this.noteType
          }
          serviceNoteAdd(params).then(res => {
            if (res.code === '1') {
              this.$message.success(this.titleD + '成功')
              this.close()
            }
          })
        }
      })
    }
  }
}
</script>

<style></style>

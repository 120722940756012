<template>
  <dialogComp :title="'转派'+ title" :isShow="isShow" :modal="false" @close="close" @sure="sure">
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="120px"
    >
      <el-form-item
        label="是否中止上门:"
        prop="cancel"
        v-if="isShowCancelScence"
      >
        <el-radio-group v-model="formData.cancel" @change="cancelChange">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <selectFormComp
        v-if="
          formData.cancel === true &&
            this.transferRole &&
            this.transferRole !== 'dispatch'
        "
        label="中止原因:"
        prop="reasonCode"
        placeholder="请选择中止原因"
        :data="reasonList"
        :hval="formData.reasonCode"
        @change="reasonCodeChange"
      />
      <inputFormComp
        inputType="textarea"
        label="备注:"
        prop="remark"
        :hval="formData.remark"
        placeholder="请输入备注"
        @blur="remarkBlur"
      />
    </el-form>
  </dialogComp>
</template>

<script>
import { workOrderTransfer, dictList } from '@/api'
export default {
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    // 转派哪个角色
    transferRole: {
      type: String,
      default: ''
    },
    Pinfo: {
      type: Object,
      default: () => {}
    },
    IndexInfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      reasonList: [],
      formData: {
        remark: '',
        cancel: false,
        reasonCode: null,
        reasonContent: ''
      },
      rules: {
        cancel: [
          { required: true, message: '请选择是否中止上门', trigger: 'change' }
        ],
        reasonCode: [
          { required: true, message: '请选择中止原因', trigger: 'change' }
        ],
        remark: [
          { required: true, message: '请输入备注', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    // 是否显示中止上门 (必须有上门处理且转派的角色不是调度)
    isShowCancelScence () {
      return (
        this.Pinfo &&
        this.Pinfo.haveVisit &&
        this.transferRole &&
        this.transferRole !== 'dispatch'
      )
    },
    // 动态标题
    title () {
      let title = ''
      switch (this.transferRole) {
        case 'service':
          title = '客服'
          break
        case 'aftermarket':
          title = '售后'
          break
        case 'store':
          title = '商家'
          break
        case 'dispatch':
          title = '调度'
          break
        default:
          break
      }
      return title
    }
  },
  watch: {
    isShow (val) {
      if (val) {
        // 获取中止原因列表
        this.getSelectData(101329)
      }
    }
  },
  methods: {
    async getSelectData (parentCode) {
      const params = {
        curPage: 1,
        pageSize: 100,
        level: 2,
        parentCode
      }
      const res = await dictList(params)
      if (res.code === '1') {
        this.reasonList = res.data.list.map(item => ({
          label: item.name,
          value: item.code
        }))
      }
    },
    cancelChange () {
      this.formData.reasonCode = null
      this.formData.reasonContent = ''
    },
    reasonCodeChange (val) {
      this.formData.reasonCode = val
      this.formData.reasonContent = this.reasonList.find(
        item => item.value === val
      ).label
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.formData.reasonContent = ''
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const params = {
            ...this.formData,
            id: this.IndexInfo.id,
            businessRole: this.transferRole
          }
          workOrderTransfer(params).then(res => {
            if (res.code === '1') {
              this.$message.success('转派成功')
              this.close()
            }
          })
        }
      })
    }
  }
}
</script>

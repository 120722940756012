<template>
  <div class="remote_handle_detail" v-loading="loading">
    <div class="remote_handle_detail_top">
      <div class="info">
        <div class="btns">
          <btnComp
            round
            :disabled="
              info.status === 100 || !(info.status >= 50 && info.status <= 80)
            "
            @click="toServerVisit"
            v-permission="'完成转回访'"
            >完成转回访</btnComp
          >
          <btnComp
            round
            btnType="danger"
            @click="cancelOrder"
            v-if="info.status !== 10001"
            :disabled="
              info.status === 1000 || info.status === 1 || info.status === 100
            "
            v-permission="'取消工单'"
            >取消工单</btnComp
          >
          <btnComp
            round
            :disabled="info.firstOrderId > 0"
            @click="sendSceneBtn"
            v-permission="'二次处理'"
            >二次处理</btnComp
          >
          <btnComp round @click="urgeClick" v-permission="'客户催促'">
            <span style="color:yellow">[ {{ info.urgeNum }} ] </span>
            客户催促+1
          </btnComp>
          <btnComp
            round
            :disabled="info.complaint"
            :btnType="info.complaint ? 'warning' : 'info'"
            @click="complaintClick"
            v-permission="'客户投诉'"
            >{{ info.complaint ? '有客户投诉' : '无客户投诉' }}</btnComp
          >
          <btnComp round @click="doCopy">
            一键复制订单信息
          </btnComp>
          <btnComp round v-permission="'改工单类型'" @click="orderTypeChange">
            改工单类型
          </btnComp>
          <btnComp
            round
            :disabled="info.guestSentiment"
            :btnType="info.guestSentiment ? 'primary' : 'info'"
            @click="guestSentimentClick"
            v-permission="'客情单'"
            >{{ info.guestSentiment ? '是客情单' : '非客情单' }}</btnComp
          >
          <btnComp
            round
            style="margin-left:10px"
            v-permission="'修改信息'"
            @click="handleUpdateInfo"
            >修改信息</btnComp
          >
        </div>
        <ul class="common">
          <li>
            客户报修号：<span>{{ info.repairCode || '-' }}</span>
          </li>
          <li>
            建单时间：<span>{{
              info.createdTime && info.createdTime.slice(0, -3)
            }}</span>
          </li>
          <li>
            发货6日内故障：
            <span :style="{ color: info.poorUnpacking ? 'red' : '' }">{{
              info.poorUnpacking ? '是' : '否'
            }}</span>
          </li>
          <li>
            来源：<span>{{ info.sourceName }} {{ info.sourceCode }}</span>
          </li>
        </ul>
        <ul class="common dashed">
          <li>
            跟单状态：
            <span>
              <span :style="{ color: info.quickResponse ? '' : 'red' }">{{
                info.quickResponse ? '已响应' : '待响应'
              }}</span
              >，
              <span :style="{ color: info.quickHandle ? '' : 'red' }">{{
                info.quickHandle ? '已处理' : '待处理'
              }}</span>
            </span>
          </li>
          <li>
            起租日期：<span>{{
              info.rentStartDate && info.rentStartDate.slice(0, 10)
            }}</span>
          </li>
          <li>
            发货日期：<span>{{
              info.deliverGoodsDate && info.deliverGoodsDate.slice(0, 10)
            }}</span>
          </li>
          <li>
            快递信息：<span
              >待发货{{ info.notConsignment }}，已签收{{
                info.finishConsignment
              }}</span
            >
          </li>
        </ul>
        <ul class="common">
          <li>
            订单编号：
            <router-link
              v-if="$route.path !== '/crm'"
              target="_blank"
              :to="{
                name: 'order-list-detail',
                query: { orderId: info.orderCode }
              }"
            >
              {{ info.orderCode }}
            </router-link>
            <span v-else>{{ info.orderCode }}</span>
          </li>
          <li>
            设备类目：<span>
              {{ info.categoryName }}（{{ info.amount }}台报障）
            </span>
          </li>
          <li style="flex:2">
            商品名称：
            <a
              :href="
                `https://www.zugeqifu.com/goodsDetail/${info.commodityId}`
              "
              target="_blank"
              >{{ info.commodityName }}</a
            >
          </li>
        </ul>
        <ul class="common dashed">
          <li>
            企业名称：
            <span>
              {{ info.customerName }}
              <img
                v-if="info.customerLabel === 'VIP'"
                src="~@/assets/images/vip.png"
                alt=""
                width="20"
              />
            </span>
          </li>
          <li>
            下单人姓名：<span>{{ info.placeContacter || '-' }}</span>
          </li>
          <li>
            下单人手机号：<span>{{ info.placeContacterMobile || '-' }}</span>
          </li>
          <li>
            附加配件：
            <router-link
              v-if="isPartsOrder"
              target="_blank"
              :to="{
                name: 'order-list-detail',
                query: { orderId: info.orderCode, from: 'workOrderDetail' }
              }"
              >有配件订单</router-link
            >
            <span v-else>-</span>
          </li>
        </ul>
        <ul class="common">
          <li>
            报修人：<span style="color:#E6A23C">{{
              info.repairContacter
            }}</span>
          </li>
          <li>
            联系方式：<span style="color:#E6A23C">{{
              info.contacterMobile
            }}</span>
          </li>
          <li style="flex:2">
            实际地址：
            <span>
              {{ info.provinceCityRegion }} {{ info.detailAddress }}
              <a
                target="_blank"
                :href="
                  `https://map.baidu.com/search/${info.provinceCityRegion +
                    info.detailAddress}/?querytype=s&da_src=shareurl&wd=${info.provinceCityRegion +
                    info.detailAddress}`
                "
                >查看地图</a
              >
            </span>
          </li>
        </ul>
        <ul class="common">
          <!-- 销售查单不显示发货商家 -->
          <li v-if="$route.query.detailType !== 'sales-see'">
            发货商家：<span>{{ info.storeName || '-' }}</span>
          </li>
          <li style="flex:3">
            客服备注：
            <span :style="{ color: info.remark ? '#FF7F00' : '' }">
              {{ info.remark || '-' }}
            </span>
          </li>
        </ul>
      </div>
      <tabsComp :tabsList="tabsListNew" :hval="active" @change="tabsChange" />
    </div>
    <!-- 远程处理 -->
    <remoteHandle
      v-if="active === '1'"
      @updateStatus="updateStatus"
      :Pinfo="info"
    />
    <!-- 上门处理 -->
    <sceneHandle
      v-if="active === '2'"
      :Pinfo="info"
      @updateStatus="updateStatus"
    />
    <!-- 工单日志 -->
    <logHandle v-if="active === '3'" />
    <!-- 快递信息 -->
    <expressInfo v-if="active === '4'" :Pinfo="info" />
    <!-- 客服回访 -->
    <serviceVisit
      v-if="active === '5'"
      :Pinfo="info"
      @updateStatus="updateStatus"
    />
    <!-- 相关工单 -->
    <customOrder v-if="active === '6'" :Pinfo="info" />
    <!-- 品控管理 -->
    <qcManage
      v-if="active === '7'"
      :Pinfo="info"
      @updateStatus="updateStatus"
    />
    <!-- 相关聊天 -->
    <aboutChat v-if="active === '8'" :Pinfo="info" />
    <!-- 取消工单 -->
    <cancelOrder :isShow="cancelShow" :IndexInfo="IndexInfo" @close="close" />
    <!-- 完成转回访弹窗 -->
    <toVisit
      :isShow="toVisitShow"
      :createdTime="info.createdTime"
      :orderId="info.id"
      :diffTime="diffTime"
      @close="close"
    />
    <!-- 客户投诉 -->
    <complaint :isShow="complaintShow" :IndexInfo="IndexInfo" @close="close" />
    <!-- 客情单 -->
    <guestSentiment
      :isShow="guestSentimentShow"
      :IndexInfo="IndexInfo"
      @close="close"
    />
    <!-- 二次上门/处理 -->
    <secondScene :isShow="sceneShow" :IndexInfo="IndexInfo" @close="close" />
    <!-- 修改工单类型 -->
    <orderType
      :isShow="orderTypeShow"
      :IndexInfo="IndexInfo"
      :Pinfo="info"
      @close="close"
    />
    <!-- 修改电话及地址 -->
    <updateInfo
      :isShow="infoShow"
      :IndexInfo="IndexInfo"
      :Pinfo="info"
      @close="close"
    />
  </div>
</template>
<script>
import remoteHandle from './remote-handle'
import logHandle from './log-handle'
import sceneHandle from './scene-handle'
import expressInfo from './express-info'
import customOrder from './custom-order'
import serviceVisit from './service-visit'
import qcManage from './qc-manage'
import aboutChat from './about-chat'
import cancelOrder from './dialog/cancelOrder'
import toVisit from './dialog/toVisit'
import complaint from './dialog/complaint'
import secondScene from './dialog/secondScene'
import orderType from './dialog/orderType'
import guestSentiment from './dialog/guestSentiment'
import updateInfo from './dialog/updateInfo'
import {
  workOrderDetail,
  completeReturnVisit,
  workOrderUpdate,
  customerUrge,
  judgePartsOrder
} from '@/api'
// import { formatDate } from '@/utils/common'
import moment from 'moment'
import { mapState } from 'vuex'
export default {
  name: 'orderDetail',
  components: {
    remoteHandle,
    logHandle,
    sceneHandle,
    expressInfo,
    serviceVisit,
    cancelOrder,
    toVisit,
    customOrder,
    complaint,
    qcManage,
    aboutChat,
    secondScene,
    orderType,
    guestSentiment,
    updateInfo
  },
  data () {
    return {
      loading: false,
      cancelShow: false,
      toVisitShow: false,
      complaintShow: false,
      sceneShow: false,
      orderTypeShow: false,
      guestSentimentShow: false,
      infoShow: false,
      diffTime: '',
      info: {},
      isPartsOrder: false,
      tabsList: [
        {
          name: '1',
          label: '远程处理'
        },
        {
          name: '2',
          label: '上门处理'
        },
        {
          name: '3',
          label: '工单日志'
        },
        {
          name: '4',
          label: '快递信息'
        },
        {
          name: '5',
          label: '客服回访'
        },
        {
          name: '6',
          label: '相关工单'
        },
        {
          name: '7',
          label: '品控管理'
        },
        {
          name: '8',
          label: '相关聊天'
        }
      ],
      tabsListNew: [],
      active: '',
      tableData: []
    }
  },
  watch: {
    drawerShow: {
      immediate: true,
      handler (val) {
        if (val) {
          this.getDetail(true)
        } else {
          // 关闭侧弹窗 把所有弹窗show设置为false，解决按esc键，只关闭侧弹窗，没有关闭其他弹窗
          this.initDialog()
        }
      }
    }
  },
  computed: {
    ...mapState(['drawerShow', 'IndexInfo', 'serialNumArr']),
    // 建单与当前时间相差大于72个小时 才出现弹窗
    diffSeconds () {
      const date1 = moment(this.info.createdTime)
      const date2 = moment(new Date())
      const date3 = date2.diff(date1, 'seconds') // 计算相差的秒数
      return date3
    }
  },
  methods: {
    initDialog () {
      this.cancelShow = false
      this.toVisitShow = false
      this.complaintShow = false
      this.sceneShow = false
      this.orderTypeShow = false
      this.guestSentimentShow = false
      this.infoShow = false
    },
    // 判断是否存在配件订单
    getIsPartsOrder () {
      judgePartsOrder({ orderId: this.info.orderCode }).then(res => {
        this.isPartsOrder = res.result
      })
    },
    tabsChange (val) {
      this.active = val
    },
    getDetail (once = false) {
      this.loading = true
      workOrderDetail({ id: this.IndexInfo.id }).then(res => {
        this.loading = false
        if (res.code === '1') {
          this.info = res.data
          this.getIsPartsOrder()
          this.$emit('getInfo', this.info) // 将info信息传给父组件
          // 将当前工单详情的是否有故障标识存到store中（用于新增序列号中）
          this.$store.commit('SET_DEVICE_FAULT', this.info.poorUnpacking)
          this.tabsListNew = this.tabsList
          // 判断是否有上门处理 没有就隐藏上门处理tabs
          if (!res.data.haveVisit) {
            this.tabsListNew = this.tabsList.filter(item => item.name !== '2')
          }
          // 判断是否有客服回访tab页  品控管理tab页
          if (res.data.status < 100) {
            this.tabsListNew = this.tabsListNew.filter(
              item => item.name !== '5'
            )
          }
          // qualityControlStatus 不为null 就显示品控管理tab页
          if (res.data.qualityControlStatus === null) {
            this.tabsListNew = this.tabsListNew.filter(
              item => item.name !== '7'
            )
          }
          // 从哪里过来的
          const nameArr = this.tabsListNew.map(item => item.name)
          if (this.drawerShow) {
            const title = this.$route.meta.title
            if (title === '我的回访' || title === '客服回访') {
              if (nameArr.includes('5')) {
                this.active = '5'
              } else {
                this.active = '1'
              }
            } else if (
              title === '上门处理' ||
              title === '我的上门' ||
              title === '上门成本审核'
            ) {
              if (nameArr.includes('2')) {
                this.active = '2'
              } else {
                this.active = '1'
              }
            } else if (
              title === '商家补发' ||
              title === '客户寄回' ||
              title === '我的快递'
            ) {
              this.active = '4'
            } else if (title === '品控定责') {
              if (nameArr.includes('7')) {
                this.active = '7'
              } else {
                this.active = '1'
              }
            } else {
              // 点击进入聊天的按钮  tab栏就定位到相关聊天
              if (this.IndexInfo.clickBtnType === 'chat-btn') {
                this.active = '8'
              } else {
                this.active = '1'
              }
            }
          }
        }
      })
    },
    // 更新状态 详情接口
    updateStatus () {
      this.getDetail()
    },
    // 完成转客服回访
    toServerVisit () {
      // 建单与当前时间相差大于72个小时 才出现弹窗
      if (this.diffSeconds > 72 * 3600) {
        const time = moment.duration(this.diffSeconds, 'seconds')
        const days = time.days()
        const hours = time.hours()
        const minutes = time.minutes()
        const seconds = time.seconds()
        this.diffTime = `${days}天${hours}时${minutes}分${seconds}秒`
        this.toVisitShow = true
        return
      }
      const params = {
        id: this.info.id
      }
      completeReturnVisit(params).then(res => {
        if (res.code === '1') {
          this.$message.success('完成转客服回访成功')
          this.getDetail()
        }
      })
    },
    // 正常、紧急状态切换
    statusToggle (status) {
      const params = {
        id: this.info.id,
        premiumProcessing: status
      }
      workOrderUpdate(params).then(res => {
        if (res.code === '1') {
          this.$message.success('状态切换成功')
          this.getDetail()
        }
      })
    },
    // 客户催促
    urgeClick () {
      customerUrge({ id: this.info.id }).then(res => {
        this.$message.success('催促+1')
        this.getDetail()
      })
    },
    // 客户投诉
    complaintClick () {
      this.complaintShow = true
    },
    // 客情单
    guestSentimentClick () {
      this.guestSentimentShow = true
    },
    // 改工单类型
    orderTypeChange () {
      this.orderTypeShow = true
    },
    // 取消工单
    cancelOrder () {
      this.cancelShow = true
    },
    // 二次上门
    sendSceneBtn () {
      this.sceneShow = true
    },
    // 修改信息
    handleUpdateInfo () {
      this.infoShow = true
    },
    // 一键复制订单信息 包括工单号，订单号，商品名称，报修人，电话，地址，序列号
    doCopy () {
      const {
        repairCode,
        code,
        orderCode,
        commodityName,
        repairContacter,
        contacterMobile,
        provinceCityRegion,
        detailAddress
      } = this.info
      // 拼接信息
      const msg0 = `客户报修号：${repairCode}`
      const msg1 = `工单编号：${code}`
      const msg2 = `订单编号：${orderCode}`
      const msg3 = `商品名称：${commodityName}`
      const msg4 = `报修人：${repairContacter} ${contacterMobile}`
      const msg5 = `地址：${provinceCityRegion}${detailAddress}`
      const msg6 = `序列号：${this.serialNumArr.join('，')}`
      const message = [msg0, msg1, msg2, msg3, msg4, msg5, msg6].join('\n')
      this.$copyText(message).then(
        e => {
          this.$message.success('复制成功')
        },
        e => {
          this.$message.success('复制失败')
        }
      )
    },
    close () {
      this.cancelShow = false
      this.toVisitShow = false
      this.complaintShow = false
      this.sceneShow = false
      this.orderTypeShow = false
      this.guestSentimentShow = false
      this.infoShow = false
      this.getDetail()
    }
  }
}
</script>
<style lang="scss" scoped>
.remote_handle_detail {
  height: 100%;
}
.remote_handle_detail_top {
  padding-bottom: 2px;
  border-bottom: 1px solid #e8e8e8;
}
.info {
  padding: 10px 24px 0 40px;
  background-color: #fff;
  .btns {
    display: flex;
    margin: 5px 0;
    .el-button {
      margin-right: 10px;
    }
  }
  .dashed {
    border-bottom: 1px dashed #ddd;
  }
  .common {
    display: flex;
    padding: 5px 0;
    li {
      flex: 1;
      span {
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
}
</style>

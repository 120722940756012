<template>
  <div class="main_box">
    <div class="main_box_con">
      <div class="btns">
        <btnComp icon="el-icon-plus" @click="expressAdd('add')" v-permission="'快递新增'">快递</btnComp>
        <btnComp icon="el-icon-plus" @click="expressAdd('addRemark')" v-permission="'待发货备忘新增'">待发货备忘</btnComp>
      </div>
      <template v-if="expressData.length === 0">
        <div class="none">暂无快递信息</div>
      </template>
      <div v-loading="loading" v-else>
        <div class="item" v-for="(item,index) in expressData" :key="index">
        <div class="item_t">
          <div class="title">{{item.typeName}}</div>
          <ul class="info">
            <li>
              <span>快递单号：</span>
              <a target="_blank" :href="expressCodeURL(item)">
                {{item.expressCompanyName}}  {{item.expressCode}}
              </a>
            </li>
            <li>
              <span>状态：</span>
              <span>{{item.statusName}}</span>
            </li>
            <li>
              <span>签收时间：</span>
              <span>{{item.signForTime || '-'}}</span>
            </li>
          </ul>
          <ul class="info">
            <li>
              <span>平台备注：</span>
              <span>{{item.remark || '-'}}</span>
            </li>
            <li>
              <span>商家备注：</span>
              <span>{{item.storeRemark || '-'}}</span>
            </li>
            <li>
              <span>客户备注：</span>
              <span>{{item.customerRemark || '-'}}</span>
            </li>
          </ul>
          <div class="reason">
            <div>
              <span>异常原因：</span>
              <span :style="{color:item.abnormalReason?'#FF7F00':''}">{{item.abnormalReason || '-'}}</span>
            </div>
            <div>
              <btnComp @click="expressAdd('edit',item)" v-permission="'快递编辑'">编辑</btnComp>
              <btnComp btnType="warning" v-if="item.status!==3 && item.expressCode" @click="sign(item)" v-permission="'手动签收'">手动签收</btnComp>
            </div>
          </div>
          <div class="line"></div>
        </div>
        <div class="express_con" v-if="item.list.length > 0">
          <div>
            <div class="item_c" v-for="(citem,cindex) in item.list" :key="cindex">
            <ul class="timeline">
              <li class="time">
                <div class="icon">
                  <div class="circle" :class="{'el-icon-success':citem.status === '3'}"></div>
                  <div class="line" v-if="citem.status > '0'"></div>
                </div>
                <div class="ctime">{{citem.time}}</div>
                <div class="content"> {{citem.expressStatus}}</div>
              </li>
          </ul>
          </div>
          </div>
          <!-- 轨迹图暂时隐藏 -->
          <!-- <div class="track">
              <Track :coordinates ="item.list"/>
          </div> -->
        </div>

      </div>
      </div>
    </div>
     <!-- 快递签收 -->
    <express :isShow="expressShow" :info="info" :Pinfo="Pinfo" :dialogType="dialogType" @close="close" />
    <!-- 手动签收 -->
    <sign :isShow="signShow" :info="info" @close="close" />
  </div>
</template>

<script>
// import Track from './track'
import express from './express'
import sign from './sign'
import { expressListByOrder } from '@/api'
import { mapState } from 'vuex'
export default {
  components: {
    // Track,
    express,
    sign
  },
  props: {
    Pinfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      expressShow: false,
      loading: false,
      signShow: false,
      dialogType: '',
      expressData: [],
      info: {}
    }
  },
  computed: {
    ...mapState(['IndexInfo', 'drawerShow'])
  },
  watch: {
    drawerShow: {
      handler (val) {
        if (val) {
          this.getList()
        } else {
          // 关闭侧弹窗 把所有弹窗show设置为false，解决按esc键，只关闭侧弹窗，没有关闭其他弹窗
          this.initDialog()
        }
      }
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    // 初始化每个弹窗
    initDialog () {
      this.expressShow = false
      this.signShow = false
    },
    getList () {
      this.loading = true
      expressListByOrder({ orderId: this.IndexInfo.id }).then(res => {
        this.loading = false
        if (res.code === '1') {
          this.expressData = res.data
          this.expressData && this.expressData.forEach(item => {
            item.list && item.list.reverse()
          })
        }
      })
    },
    // 根据不同的快递公司查询单号
    expressCodeURL (row) {
      const { expressCompany, expressCode } = row
      let url = ''
      // 快递公司，1：顺丰，2：德邦，3：申通，4：京东
      switch (expressCompany) {
        case 1:
          url = `https://www.sf-express.com/cn/sc/dynamic_function/waybill/#search/bill-number/${expressCode}`
          break
        case 2:
          url = `https://www.deppon.com/track/ordertrack?orderNumList=%5B%22${expressCode}%22%5D`
          break
        // case 3:
        //   url = `https://www.baidu.com/s?ie=utf8&oe=utf8&wd=${expressCode}&tn=98010089_dg&ch=3`
        //   break
        default:
          url = `https://www.baidu.com/s?ie=utf8&oe=utf8&wd=${expressCode}`
          break
      }
      return url
    },
    // 处理经纬度数据
    handlecoordinates (data) {
      if (data) {
        return data.filter(item => item.longitude && item.latitude).map(item => ([+item.longitude, +item.latitude]))
      }
    },
    expressAdd (type, info) {
      this.dialogType = type
      this.expressShow = true
      if (type === 'edit') {
        this.info = info
      }
    },
    // 手动签收
    sign (info) {
      this.signShow = true
      this.info = info
    },
    close () {
      this.expressShow = false
      this.signShow = false
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.btns {
  margin-bottom: 20px;
}
.none {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 440px);
  background-color: #fff;
}
.timeline {
    display: flex;
    margin-top: 20px;
    height: 40px;
    .time {
      position: relative;
      display: flex;
      align-items: center;
      .icon {
        position: relative;
        .circle {
          position: absolute;
          left: 0;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #DFDFDF;
          &.el-icon-success {
            color: #0EBE7B;
            background-color: transparent;
            font-size: 18px;
            left: -4px;
          }
        }

      .line {
        position: absolute;
        top: 20px;
        left:3px;
        width: 1px;
        height: 30px;
        background-color: #DFDFDF;
      }
      }
      .ctime {
        position: relative;
        left: 40px;
        top: 8px;
      }
      .content {
         position: relative;
        left: 60px;
        top: 8px;
      }
    }
  }
  .item {
    padding: 0 20px 20px 20px;
    background-color: #fff;
    border-radius: 2px;
    // margin-bottom: 24px;
    .title {
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
    }
    .info {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 10px;
      li {
        flex: 1;
      }
    }
    .reason {
      display: flex;
      justify-content: space-between;
       align-items: center;
       margin-top: 10px;
    }
    .line {
      border-bottom: 1px solid #E8E8E8;
      margin-top: 10px;
    }
  }
  .express_con {
    display: flex;
    height: 180px;
    // margin-top: 20px;
    .track {
      flex: 1;
      margin-left: 90px;
    }
  }
</style>

<template>
  <div class="main_box">
    <div class="main_box_con">
      <div class="service">
        <div class="btns">
          <btnComp
            :disabled="Pinfo.status === 1000"
            @click="noVisit"
            v-permission="'无需回访'"
            >无需回访</btnComp
          >
          <btnComp
            :disabled="Pinfo.status === 1000"
            @click="submit"
            v-permission="'提交回访'"
            >提交回访</btnComp
          >
          <btnComp
            :disabled="Pinfo.status === 1000"
            @click="assign"
            v-permission="'指派回访客服'"
            >指派回访客服</btnComp
          >
        </div>
        <ul>
          <li>
            <div class="ctitle">回访状态：</div>
            <div>{{ Pinfo.serviceVisitStatusName }}</div>
          </li>
          <li>
            <div class="ctitle">总体满意度：</div>
            <el-radio-group v-model="satisfaction" size="medium">
              <el-radio :label="1">非常满意</el-radio>
              <el-radio :label="2">满意</el-radio>
              <el-radio :label="3">一般</el-radio>
              <el-radio :label="4">不满意</el-radio>
              <el-radio :label="5">未接通</el-radio>
            </el-radio-group>
          </li>
          <li>
            <div class="ctitle">不满意项：</div>
            <el-checkbox-group v-model="notSatisfiedCode" class="notSatisfied">
              <el-checkbox label="4">处理时效不满意</el-checkbox>
              <el-checkbox label="5">售后服务态度不满意</el-checkbox>
              <el-checkbox label="2">设备质量不满意</el-checkbox>
              <el-checkbox label="3">处理结果不满意</el-checkbox>
              <el-checkbox label="1">其他不满意</el-checkbox>
            </el-checkbox-group>
          </li>
          <li>
            <div class="ctitle">回访备注：</div>
            <inputComp
              inputType="textarea"
              :hval="remark"
              placeholder="请输入回访备注"
              style="width:350px"
              @blur="notSatisfiedCodeBlur"
            />
          </li>
        </ul>
      </div>
    </div>
    <assignVisit
      :isShow="assignVisitShow"
      :IndexInfo="IndexInfo"
      dialogType="single"
      @close="assignVisitShow = false"
    />
  </div>
</template>

<script>
import assignVisit from '../dialog/batchVisit'
import { serviceVisitAdd, serviceVisitNoAdd } from '@/api'
import { mapState, mapActions } from 'vuex'
export default {
  components: {
    assignVisit
  },
  props: {
    Pinfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      assignVisitShow: false,
      satisfaction: null,
      notSatisfiedCode: [],
      remark: null
    }
  },
  computed: {
    ...mapState(['IndexInfo', 'drawerShow'])
  },
  watch: {
    drawerShow: {
      handler (val) {
        if (!val) {
          this.remark = ''
          this.satisfaction = null
          this.notSatisfiedCode = []
          this.remark = null
          this.assignVisitShow = false
        }
      }
    }
  },
  mounted () {
    const value = this.Pinfo
    this.remark = value.serviceVisitRemark
    if (value.serviceVisit) {
      const { satisfaction, notSatisfiedCode } = value.serviceVisit
      this.satisfaction = satisfaction
      this.notSatisfiedCode = notSatisfiedCode
        ? notSatisfiedCode.split(',')
        : []
    }
  },
  methods: {
    ...mapActions('im', ['destroyGroupAction']),
    notSatisfiedCodeBlur (val) {
      this.remark = val
    },
    // 指派回访客服
    assign () {
      this.assignVisitShow = true
    },
    // 无需回访
    noVisit () {
      serviceVisitNoAdd({
        orderId: this.IndexInfo.id,
        remark: this.remark
      }).then(res => {
        if (res.code === '1') {
          this.$message.success('无需回访成功')
          this.$emit('updateStatus')
        }
      })
    },
    // 提交回访
    submit () {
      const params = {
        orderId: this.IndexInfo.id,
        satisfaction: this.satisfaction,
        notSatisfiedCode:
          this.notSatisfiedCode.length > 0
            ? this.notSatisfiedCode.join(',')
            : null,
        remark: this.remark
      }
      if (!this.satisfaction) {
        this.$message.error('总体满意度为必选项')
      } else if (
        this.notSatisfiedCode.length === 0 &&
        (this.satisfaction === 3 || this.satisfaction === 4)
      ) {
        this.$message.error('总体满意度选一般或者不满意，不满意项必须项')
      } else {
        console.log(serviceVisitAdd, params)
        serviceVisitAdd(params).then(res => {
          if (res.code === '1') {
            this.$message.success('提交成功')
            this.$emit('updateStatus')
            // 解散群组
            this.destroyGroupAction(this.IndexInfo.id)
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.service {
  .btns {
    margin-bottom: 30px;
  }
  li {
    display: flex;
    margin-bottom: 20px;
    .ctitle {
      width: 200px;
      text-align: right;
    }
    .notSatisfied {
      width: 340px;
      .el-checkbox {
        margin-bottom: 15px;
      }
    }
  }
}
</style>

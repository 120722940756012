<template>
  <dialogComp
    title="修改信息"
    :isShow="isShow"
    :modal="false"
    @close="close"
    @sure="sure"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="100px"
    >
      <inputFormComp
        label="报修人:"
        prop="contacterMobile"
        :hval="formData.userName"
        @blur="userNameBlur"
        placeholder="请输入报修人"
      />
      <inputFormComp
        label="联系电话:"
        prop="contacterMobile"
        :hval="formData.contacterMobile"
        @blur="contacterMobileBlur"
        placeholder="请输入联系电话"
      />
      <el-form-item label="省市区:" prop="area">
        <areaData :hval="formData.area" @change="areaChange" />
      </el-form-item>
      <inputFormComp
        label="详情地址"
        prop="detailAddress"
        :hval="formData.detailAddress"
        @blur="detailAddressBlur"
        placeholder="请输入详细地址"
      />
    </el-form>
  </dialogComp>
</template>

<script>
import areaData from '@/views/components/areaDataNew'
import { updateMobileAndAddress } from '@/api'
import { handleArea } from '@/utils/common'
export default {
  components: {
    areaData
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    Pinfo: {
      type: Object,
      default: () => {}
    },
    IndexInfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      formData: {
        contacterMobile: '',
        userName: '',
        area: [],
        province: '',
        city: '',
        region: '',
        detailAddress: ''
      },
      rules: {
        contacterMobile: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '手机号格式不正确',
            trigger: 'blur'
          }
        ],
        area: [{ required: true, message: '请选择省市区', trigger: 'change' }],
        detailAddress: [
          { required: true, message: '请输入详情地址', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {},
  watch: {
    isShow (val) {
      if (val) {
        const {
          contacterMobile,
          repairContacter,
          detailAddress,
          province,
          city,
          region,
          repairCode,
          id
        } = this.Pinfo
        this.formData = {
          area: handleArea(province, city, region),
          detailAddress,
          province,
          city,
          region,
          contacterMobile,
          userName: repairContacter,
          dataType: repairCode ? 0 : 1,
          code: repairCode || id
        }
      }
    }
  },
  methods: {
    areaChange (val) {
      this.formData.area = val
      const [a, b, c] = val
      this.formData.province = a || ''
      this.formData.city = b || ''
      this.formData.region = c || ''
    },
    userNameBlur (val) {
      this.formData.userName = val
    },
    contacterMobileBlur (val) {
      this.formData.contacterMobile = val
    },
    detailAddressBlur (val) {
      this.formData.detailAddress = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          updateMobileAndAddress(this.formData).then(_ => {
            this.$message.success('修改成功')
            this.close()
          })
        }
      })
    }
  }
}
</script>

<template>
  <dialogComp title="修改工单类型" :isShow="isShow" :modal="false" @close="close" @sure="sure">
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="100px"
    >
      <inputFormComp
        label="工单类型:"
        disabled
        :hval="Pinfo.orderTypeName"
      />
      <selectFormComp
        label="工单类型:"
        prop="orderTypeChi"
        placeholder="请选择工单类型"
        :data="orderTypeChiList"
        :hval="formData.orderTypeChi"
        @change="orderTypeChiChange"
      />
      <inputFormComp
        inputType="textarea"
        label="备注:"
        prop="remark"
        :hval="formData.remark"
        placeholder="请输入备注"
        @blur="remarkBlur"
      />
    </el-form>
  </dialogComp>
</template>

<script>
import { orderTypeUpdate } from '@/api'
import { orderTypeChiList1, orderTypeChiList2, orderTypeChiList3 } from '@/utils/constData'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    Pinfo: {
      type: Object,
      default: () => {}
    },
    IndexInfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      orderTypeChiList: [],
      formData: {
        remark: '',
        orderTypeChi: ''
      },
      rules: {
        orderTypeChi: [
          { required: true, message: '请选择工单类型', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    orderTypeChiList1 () {
      return orderTypeChiList1
    },
    orderTypeChiList2 () {
      return orderTypeChiList2
    },
    orderTypeChiList3 () {
      return orderTypeChiList3
    }
  },
  watch: {
    isShow (val) {
      if (val) {
        const { orderType, orderTypeChi, orderTypeChiRemark } = this.Pinfo
        this.orderTypeChiList = this['orderTypeChiList' + orderType]
        this.formData.orderTypeChi = orderTypeChi
        this.formData.remark = orderTypeChiRemark
      }
    }
  },
  methods: {
    orderTypeChiChange (val) {
      this.formData.orderTypeChi = val
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const params = {
            ...this.formData,
            id: this.IndexInfo.id
          }
          orderTypeUpdate(params).then(_ => {
            this.$message.success('修改成功')
            this.close()
          })
        }
      })
    }
  }
}
</script>

<template>
  <dialogComp :title="titleD" :isShow="isShow" :modal="false" @close="close" @sure="sure">
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="60px"
    >
      <el-form-item label="时间:" prop="time">
        <el-date-picker
          v-model="formData.time"
          type="datetime"
          size="small"
          placeholder="选择日期时间"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
  </dialogComp>
</template>

<script>
import { sceneUpdateStatus } from '@/api'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    // 50：出发，60：到达，70：申请验收，1000：已验收
    updateStatusN: {
      type: Number,
      default: 50
    },
    visitId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      formData: {
        time: null
      },
      rules: {
        time: [{ required: true, message: '请选择时间', trigger: 'change' }]
      }
    }
  },
  computed: {
    titleD () {
      return this.handleTitle(this.updateStatusN)
    }
  },
  watch: {
    isShow (val) {
      if (val) {
        this.formData.time = new Date()
      }
    }
  },
  methods: {
    handleTitle (status) {
      switch (status) {
        case 50:
          return '出发'
        case 60:
          return '到达'
        case 70:
          return '申请验收'
        default:
          break
      }
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const params = {
            id: this.visitId,
            ...this.formData,
            status: this.updateStatusN
          }
          sceneUpdateStatus(params).then(res => {
            if (res.code === '1') {
              this.$message.success(this.titleD + '成功')
              this.close()
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>
::v-deep .el-date-editor {
  width: 100%!important;
}
</style>

<template>
  <dialogComp title="批量处理" :isShow="isShow" :modal="false" @close="close" @sure="sure">
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="100px"
      style="text-align: center"
    >
      <el-form-item label="故障现象:" prop="failCat">
        <cascader
          :data="deviceFaultList"
          :propsParams="{
            expandTrigger: 'click',
            value: 'id',
            label: 'appearance'
          }"
          :hval="deviceFaultArr"
          @change="configFaultChange"
          placeholder="请选择故障现象"
        />
      </el-form-item>
      <el-form-item label="问题归类:" prop="failCat">
        <cascader
          :data="failCatList"
          :hval="failCatArr"
          @change="failCatChange"
          placeholder="请选择问题归类"
        />
      </el-form-item>
      <el-form-item label="处理方式:" prop="treatmentMethod">
        <cascader
          :data="treatmentMethodList"
          :hval="treatmentMethodArr"
          @change="treatmentMethodChange"
          placeholder="请选择处理方式"
        />
      </el-form-item>
      <el-form-item label="责任归属:" prop="ownershipResponsibility">
        <cascader
          :data="ownershipResponsibilityList"
          :hval="ownershipResponsibilityArr"
          @change="ownershipResponsibilityChange"
          placeholder="请选择责任归属"
        />
      </el-form-item>
      <el-form-item
        label="开箱不良:"
        prop="causeAnalysis"
        class="poorUnpacking"
      >
        <el-checkbox
          style="margin-right: 8px"
          v-model="formData.poorUnpacking"
          @change="poorUnpackingChange"
        ></el-checkbox>
        <cascader
          :data="causeAnalysisList"
          :hval="causeAnalysisArr"
          @change="causeAnalysisChange"
          placeholder="请选择开箱不良原因"
          :disabled="!formData.poorUnpacking"
        />
      </el-form-item>
    </el-form>
  </dialogComp>
</template>

<script>
import { dictThree, devicesInfoBatchUpdate, deviceFaulThree } from '@/api'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    selectIds: {
      type: Array,
      default: () => []
    },
    IndexInfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      causeAnalysisList: [],
      failCatList: [],
      treatmentMethodList: [],
      ownershipResponsibilityList: [],
      deviceFaultList: [],
      deviceFaultArr: [],
      failCatArr: [],
      treatmentMethodArr: [],
      ownershipResponsibilityArr: [],
      causeAnalysisArr: [],
      formData: {
        configFaultId: '',
        configFaultChildId: '',
        failCat: '',
        failCatOne: '',
        failCatTwo: '',
        failCatThree: '',
        treatmentMethod: '',
        treatmentMethodOne: '',
        treatmentMethodTwo: '',
        treatmentMethodThree: '',
        causeAnalysis: '',
        causeAnalysisOne: '',
        causeAnalysisTwo: '',
        causeAnalysisThree: '',
        ownershipResponsibility: '',
        ownershipResponsibilityOne: '',
        ownershipResponsibilityTwo: '',
        ownershipResponsibilityThree: '',
        poorUnpacking: null
      },
      rules: {}
    }
  },
  watch: {
    isShow (val) {
      if (val) {
        this.getSelectData(101015, 'failCatList')
        this.getSelectData(101018, 'causeAnalysisList')
        this.getSelectData(101021, 'treatmentMethodList')
        this.getSelectData(101024, 'ownershipResponsibilityList')
        this.getDeviceFaultList()
      }
    }
  },
  methods: {
    // 获取展开后  多个下拉列表 问题归类\原因分析\处理方式
    async getSelectData (code, data) {
      const params = {
        code
      }
      const res = await dictThree(params)
      if (res.code === '1') {
        this[data] = res.data
      }
    },
    // 获取故障现象下拉
    getDeviceFaultList () {
      const params = {
        categoryId: this.IndexInfo.categoryId
      }
      deviceFaulThree(params).then((res) => {
        if (res.code === '1') {
          this.deviceFaultList = res.data
        }
      })
    },
    configFaultChange (val) {
      switch (val.length) {
        case 0:
          this.formData.configFaultId = ''
          this.formData.configFaultChildId = ''
          break
        case 1:
          this.formData.configFaultId = val[0]
          this.formData.configFaultChildId = ''
          break
        case 2:
          this.formData.configFaultId = val[0]
          this.formData.configFaultChildId = val[1]
          break
      }
    },
    // 封装方法鉴定记录选择事件
    handleChangeFn (val, type, one, two, three) {
      switch (val.length) {
        case 0:
          this.formData[type] = ''
          this.formData[one] = ''
          this.formData[two] = ''
          this.formData[three] = ''
          break
        case 1:
          this.formData[type] = val[0]
          this.formData[one] = ''
          this.formData[two] = ''
          this.formData[three] = ''
          break
        case 2:
          this.formData[type] = val[0]
          this.formData[one] = val[1]
          this.formData[two] = ''
          this.formData[three] = ''
          break
        case 3:
          this.formData[type] = val[0]
          this.formData[one] = val[1]
          this.formData[two] = val[2]
          this.formData[three] = ''
          break
        case 4:
          this.formData[type] = val[0]
          this.formData[one] = val[1]
          this.formData[two] = val[2]
          this.formData[three] = val[3]
          break
      }
    },
    failCatChange (val) {
      this.handleChangeFn(val, 'failCat', 'failCatOne', 'failCatTwo', 'failCatThree')
    },
    causeAnalysisChange (val) {
      this.handleChangeFn(val, 'causeAnalysis', 'causeAnalysisOne', 'causeAnalysisTwo', 'causeAnalysisThree')
    },
    treatmentMethodChange (val) {
      this.handleChangeFn(val, 'treatmentMethod', 'treatmentMethodOne', 'treatmentMethodTwo', 'treatmentMethodThree')
    },
    ownershipResponsibilityChange (val) {
      this.handleChangeFn(val, 'ownershipResponsibility', 'ownershipResponsibilityOne', 'ownershipResponsibilityTwo', 'ownershipResponsibilityThree')
    },
    poorUnpackingChange (val) {
      this.formData.poorUnpacking = val
      // 勾选后 原因禁止选择并置
      if (!val) {
        this.causeAnalysisArr = []
        this.formData.causeAnalysis = ''
        this.formData.causeAnalysisOne = ''
        this.formData.causeAnalysisTwo = ''
        this.formData.causeAnalysisThree = ''
      }
    },
    close () {
      this.$refs.formData.resetFields()
      this.deviceFaultArr = []
      this.failCatArr = []
      this.treatmentMethodArr = []
      this.ownershipResponsibilityArr = []
      this.causeAnalysisArr = []
      this.formData = {
        configFaultId: '',
        configFaultChildId: '',
        failCat: '',
        failCatOne: '',
        failCatTwo: '',
        failCatThree: '',
        treatmentMethod: '',
        treatmentMethodOne: '',
        treatmentMethodTwo: '',
        treatmentMethodThree: '',
        causeAnalysis: '',
        causeAnalysisOne: '',
        causeAnalysisTwo: '',
        causeAnalysisThree: '',
        ownershipResponsibility: '',
        ownershipResponsibilityOne: '',
        ownershipResponsibilityTwo: '',
        ownershipResponsibilityThree: '',
        poorUnpacking: null
      }
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          const params = {
            orderId: this.IndexInfo.id,
            ids: this.selectIds,
            ...this.formData
          }
          devicesInfoBatchUpdate(params).then((res) => {
            this.$message.success('批量处理成功')
            this.close()
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .poorUnpacking {
  .el-form-item__content {
    display: flex;
  }
}
</style>

<template>
  <div class="main_box">
      <remoteHandle :showQc="true" :Pinfo="Pinfo" @updateStatus="updateStatus"/>
    </div>
</template>
<script>
import remoteHandle from '../remote-handle'
export default {
  props: {
    Pinfo: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    remoteHandle
  },
  methods: {
    updateStatus () {
      this.$emit('updateStatus')
    }
  }
}
</script>

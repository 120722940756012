<template>
  <dialogComp
    :title="titleD"
    width="640px"
    :isShow="isShow"
    :modal="false"
    @close="close"
    @sure="sure"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="100px"
    >
      <!-- <inputFormComp
        label="费用名称:"
        prop="title"
        :hval="formData.title"
        @blur="titleBlur"
        placeholder="请输入费用名称"
      /> -->
      <radioFormComp
        label="费用类型:"
        prop="type"
        :radioList="typeList"
        :hval="formData.type"
        @change="typeChange"
      />
      <inputFormComp
        inputType="number"
        label="单价:"
        prop="price"
        :hval="formData.price"
        @blur="priceBlur"
        placeholder="请输入单价"
      />
      <inputFormComp
        inputType="number"
        label="数量:"
        prop="num"
        :hval="formData.num"
        @blur="numBlur"
        placeholder="请输入数量"
      />
      <inputFormComp
        disabled
        label="小计:"
        prop="subtotal"
        :hval="formData.subtotal"
      />
      <inputFormComp
        inputType="textarea"
        label="备注:"
        prop="remark"
        :hval="formData.remark"
        @blur="remarkBlur"
        placeholder="请输入备注"
      />
    </el-form>
  </dialogComp>
</template>
<script>
import { scenePriceAdd, scenePriceEdit } from '@/api'
import { checkPrice } from '@/utils/common'
export default {
  name: '',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'add'
    },
    visitId: {
      type: String,
      default: ''
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data () {
    return {
      typeList: [
        { label: '上门费', value: 1 },
        { label: '维修费', value: 2 },
        { label: '附加费', value: 3 }
      ],
      formData: {
        title: '',
        num: '',
        price: '',
        subtotal: '',
        type: 1,
        remark: ''
      },
      rules: {
        // title: [{ required: true, message: '请输入费用名称', trigger: 'blur' }],
        price: [
          { required: true, message: '请输入单价', trigger: 'blur' },
          { validator: checkPrice, trigger: 'blur' }
        ],
        num: [
          {
            pattern: /^[0-9]+$/,
            required: true,
            message: '只能输入整数',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    titleD () {
      return this.title === 'add' ? '新增上门费用' : '编辑上门费用'
    }
  },
  watch: {
    isShow (val) {
      if (val && this.title === 'edit') {
        const { num, price, subtotal, type, remark } = this.info
        this.handleTitleByType(type)
        this.formData.num = num
        this.formData.price = price
        this.formData.subtotal = subtotal
        this.formData.type = type
        this.formData.remark = remark
      }
    }
  },
  created () {},
  mounted () {},
  methods: {
    // titleBlur (val) {
    //   this.formData.title = val
    // },
    handleTitleByType (type) {
      switch (type) {
        case 1:
          this.formData.title = '上门费'
          break
        case 2:
          this.formData.title = '维修费'
          break
        case 3:
          this.formData.title = '附加费'
          break
      }
    },
    typeChange (val) {
      this.formData.type = val
      this.handleTitleByType(val)
    },
    priceBlur (val) {
      this.formData.price = val
      if (this.formData.price && this.formData.num) {
        this.formData.subtotal = (
          this.formData.price * this.formData.num
        ).toFixed(2)
      }
    },
    numBlur (val) {
      this.formData.num = val
      if (this.formData.price && this.formData.num) {
        this.formData.subtotal = (
          this.formData.price * this.formData.num
        ).toFixed(2)
      }
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          if (this.title === 'add') {
            scenePriceAdd({ ...this.formData, visitId: this.visitId }).then(
              res => {
                if (res.code === '1') {
                  this.$message.success('添加成功')
                  this.close()
                }
              }
            )
          } else {
            scenePriceEdit({
              ...this.formData,
              id: this.info.id,
              visitId: this.visitId
            }).then(res => {
              if (res.code === '1') {
                this.$message.success('编辑成功')
                this.close()
              }
            })
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped></style>

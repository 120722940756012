<template>
  <dialogComp
    title="手动签收"
    :isShow="isShow"
    :modal="false"
    @close="close"
    @sure="sure"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="100px"
    >
      <el-form-item label="签收时间:" prop="signForTime">
        <el-date-picker
        v-model="formData.signForTime"
        type="datetime"
        size="small"
        style="width:100%"
        placeholder="选择日期时间">
      </el-date-picker>
      </el-form-item>
      <inputFormComp
        inputType="textarea"
        label="备注:"
        prop="remark"
        :hval="formData.remark"
        @blur="remarkBlur"
        placeholder="请输入备注"
      />
    </el-form>
  </dialogComp>
</template>

<script>
import { expressEdit } from '@/api'
import { formatDate } from '@/utils/common'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      expressCompanyName: '',
      formData: {
        signForTime: '',
        remark: ''
      },
      rules: {
        signForTime: [
          { required: true, message: '请选择签收时间', trigger: 'change' }
        ]
      }
    }
  },
  watch: {
    isShow (val) {
      if (val) {
        const { remark, signForTime } = this.info
        this.formData.remark = remark
        if (signForTime) {
          this.formData.signForTime = signForTime
        }
      }
    }
  },
  methods: {
    remarkBlur (val) {
      this.formData.remark = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const { signForTime, remark } = this.formData
          const params = {
            id: this.info.id,
            status: 3,
            abnormal: false,
            signForTime: formatDate(signForTime, 'YYYY-MM-DD HH:mm:ss'),
            remark
          }
          expressEdit(params).then(res => {
            if (res.code === '1') {
              this.$message.success('手动签收成功')
              this.close()
            }
          })
        }
      })
    }
  }
}
</script>

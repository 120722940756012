<template>
  <div class="remote_handle_detail">
    <div
      class="main_box"
      :style="{ padding: !showHandle && !showQc ? '20px' : '0' }"
    >
      <div class="main_box_con">
        <div class="search_flex">
          <!-- 远程处理tab的按钮 -->
          <div class="search_left" v-if="!showHandle && !showQc">
            <btnComp
              @click="fastHanlde(1)"
              :disabled="!!Pinfo.quickResponse"
              v-permission="'快速响应'"
              >快速响应</btnComp
            >
            <btnComp
              @click="fastHanlde(2)"
              :disabled="!!Pinfo.quickHandle"
              v-permission="'快速处理'"
              >快速处理</btnComp
            >
            <btnComp
              icon="el-icon-plus"
              @click="remarkAdd('orderRemarkAdd')"
              v-permission="'工单日志新增'"
              >工单日志</btnComp
            >
            <btnComp
              icon="el-icon-plus"
              @click="serialAdd"
              v-permission="'序列号新增'"
              >序列号</btnComp
            >
            <btnComp
              btnType="plain"
              :disabled="allotDisabled"
              @click="batchHandle"
              v-permission="'批量处理'"
              >批量处理</btnComp
            >
            <btnComp
              v-if="Pinfo.qualityControlStatus===null || Pinfo.qualityControlStatus===0"
              v-permission="'复查通过'"
              @click="qcCheckAudit(1)"
              >复查通过</btnComp
            >
            <el-dropdown
              trigger="hover"
              style="margin-left: 10px"
              @command="handleTransfer"
              v-permission="'转派'"
            >
              <el-button type="primary" size="small">
                转派<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :disabled="Pinfo.status === 1000"
                  command="store"
                  >转派商家</el-dropdown-item
                >
                <el-dropdown-item
                  :disabled="Pinfo.status === 1000"
                  command="aftermarket"
                  >转派售后</el-dropdown-item
                >
                <el-dropdown-item
                  :disabled="Pinfo.status === 1000"
                  command="dispatch"
                  >转派调度</el-dropdown-item
                >
                <el-dropdown-item
                  :disabled="Pinfo.status === 1000"
                  command="service"
                  >转派客服</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <btnComp
              @click="redeploy"
              :disabled="Pinfo.status === 1000"
              v-permission="'转交'"
              >转交</btnComp
            >
          </div>
          <!--上门处理tab的按钮 -->
          <div v-else-if="showHandle" style="margin-bottom:20px">
            <btnComp
              icon="el-icon-plus"
              @click="serialAdd"
              v-permission="'序列号新增'"
              >序列号</btnComp
            >
            <btnComp
              btnType="plain"
              :disabled="allotDisabled"
              @click="batchHandle"
              v-permission="'批量处理'"
              >批量处理</btnComp
            >
          </div>
          <!--品控管理tab的按钮 -->
          <div v-else-if="showQc" style="margin-bottom:20px">
            <btnComp v-if="!isStoreRole" v-permission="'品控审核完成'" :disabled="Pinfo.qualityControlStatus !==1" @click="qcCheckAudit(2)" >审核完成</btnComp>
            <btnComp v-permission="'商家确认'" :disabled="Pinfo.qualityControlStatus !==2" @click="qcCheckAudit(3)" >商家确认</btnComp>
            <btnComp v-if="!isStoreRole" v-permission="'取消变更提醒'" :disabled="allotDisabled" @click="cancleDiff" >批量取消变更提醒(责任及开箱不良)</btnComp>
          </div>
        </div>
        <div
          class="remote_handle_detail_con clearfix"
          :style="{ marginTop: !showHandle && !showQc ? '24px' : '0' }"
        >
          <el-table
            class="table_expand"
            ref="table"
            lazy
            :data="tableData"
            style="width: 100%"
            :header-cell-style="{
              color: 'rgba(0,0,0,0.85)',
              background: '#FAFAFA'
            }"
            :row-key="getRowKeys"
            :expand-row-keys="expands"
            @select="select"
            @select-all="selectAll"
          >
            <el-table-column type="selection" width="30"> </el-table-column>
            <el-table-column label="序列号" prop="header" min-width="120">
              <template v-slot="scope">
                <div style="display:flex;flex-wrap: wrap;">
                  <div>
                    {{ scope.row.serialNum || '-' }}
                    <btnComp
                      btnType="text"
                      v-permission="'序列号编辑'"
                      @click="serialEdit(scope.row)"
                      >编辑</btnComp
                    >
                    <btnComp
                      btnType="text"
                      style="color:#E6A23C"
                      @click="serialDel(scope.row.id)"
                      v-permission="'序列号清空'"
                      >清空</btnComp
                    >
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="数量" prop="num" width="100">
              <template v-slot="scope">
                <!-- 品控只展示不能修改 -->
                <span v-if="showQc">
                  {{scope.row.num}}
                </span>
                <el-input v-else type="number" size="small" v-model="scope.row.num">
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="故障现象" min-width="140">
              <template v-slot="scope">
                <cascader
                  :disabled="showQc"
                  :data="deviceFaultList"
                  :hval="
                    filterArrNull([
                      scope.row.configFaultId,
                      scope.row.configFaultChildId
                    ])
                  "
                  :propsParams="{
                    expandTrigger: 'click',
                    value: 'id',
                    label: 'appearance'
                  }"
                  @change="configFaultChange(scope.$index, $event)"
                  placeholder="请选择故障现象"
                />
              </template>
            </el-table-column>
            <el-table-column label="问题归类" min-width="140">
              <template v-slot="scope">
                <cascader
                  :disabled="showQc"
                  :data="failCatList"
                  :hval="
                    filterArrNull([
                      scope.row.failCat,
                      scope.row.failCatOne,
                      scope.row.failCatTwo,
                      scope.row.failCatThree
                    ])
                  "
                  @change="failCatChange(scope.$index, $event)"
                  placeholder="请选择问题归类"
                />
              </template>
            </el-table-column>
            <el-table-column label="处理方式" min-width="140">
              <template v-slot="scope">
                <cascader
                  :disabled="showQc"
                  :data="treatmentMethodList"
                  :hval="
                    filterArrNull([
                      scope.row.treatmentMethod,
                      scope.row.treatmentMethodOne,
                      scope.row.treatmentMethodTwo,
                      scope.row.treatmentMethodThree
                    ])
                  "
                  @change="treatmentMethodChange(scope.$index, $event)"
                  placeholder="请选择处理方式"
                />
              </template>
            </el-table-column>
            <!-- 品控管理和远程远程处理的区别    责任归属、开箱不良字段不一样  还多了一个处罚金额 -->
            <el-table-column label="责任归属" min-width="140">
              <template v-slot="scope">
                <cascader
                  :data="ownershipResponsibilityList"
                  :hval="
                  showQc?
                    filterArrNull([
                      scope.row.qualityOwnershipResponsibility,
                      scope.row.qualityOwnershipResponsibilityOne,
                      scope.row.qualityOwnershipResponsibilityTwo,
                      scope.row.qualityOwnershipResponsibilityThree
                    ]):
                    filterArrNull([
                      scope.row.ownershipResponsibility,
                      scope.row.ownershipResponsibilityOne,
                      scope.row.ownershipResponsibilityTwo,
                      scope.row.ownershipResponsibilityThree
                    ])
                  "
                  @change="ownershipResponsibilityChange(scope.$index, $event)"
                  placeholder="请选择责任归属"
                />
              </template>
            </el-table-column>
            <el-table-column label="开箱不良" min-width="140">
              <template v-slot="scope">
                <div class="flex" style="align-items:center">
                  <el-checkbox
                    style="margin-right:8px"
                    v-model="scope.row.poorUnpacking"
                    @change="poorUnpackingChange(scope.$index, $event)"
                  ></el-checkbox>
                  <cascader
                    :data="causeAnalysisList"
                    :hval="
                    showQc?
                       filterArrNull([
                        scope.row.qualityCauseAnalysis,
                        scope.row.qualityCauseAnalysisOne,
                        scope.row.qualityCauseAnalysisTwo,
                        scope.row.qualityCauseAnalysisThree
                      ]):
                      filterArrNull([
                        scope.row.causeAnalysis,
                        scope.row.causeAnalysisOne,
                        scope.row.causeAnalysisTwo,
                        scope.row.causeAnalysisThree
                      ])
                    "
                    @change="causeAnalysisChange(scope.$index, $event)"
                    placeholder="请选择开箱不良原因"
                    :disabled="!scope.row.poorUnpacking"
                  />
                </div>
              </template>
            </el-table-column>
            <!-- 品控管理有处罚金额 -->
            <el-table-column v-if="showQc" label="处罚金额" min-width="140">
              <template v-slot="scope">
                <div class="flex" style="align-items:center">
                  <el-checkbox
                    style="margin-right:8px"
                    v-model="scope.row.punish"
                    @change="punishChange(scope.$index, $event)"
                  ></el-checkbox>
                  <el-input
                    :disabled="!scope.row.punish"
                    inputType="number"
                    style="width:100%"
                    v-model="scope.row.penaltyAmount"
                    @blur="penaltyAmountBlur(scope.$index, $event)"
                  ></el-input>
                </div>
              </template>
            </el-table-column>
            <!-- 商家角色不显示有无变更选项 -->
            <el-table-column v-if="showQc && !isStoreRole" label="有无变更" width="80">
              <template v-slot="scope">
                <span :style="{color:scope.row.dataSyn?'':'#ff6700'}">{{scope.row.dataSyn?'-':'有变更'}}</span>
              </template>
            </el-table-column>
            <el-table-column type="expand" width="8">
              <template slot-scope="scope">
                <div class="expand">
                  <div class="expand_right">
                    <!-- 远程处理 -->
                     <template v-if="!showQc">
                      <ul>
                        <li v-for="item in scope.row.remarkData" :key="item.id">
                          {{ item.createdTime.slice(5) }}
                          {{ item.createdByRole || '客户' }}({{ item.createdByName }})：{{
                            handleRemark(
                              item.funcCode,
                              item.remark,
                              item.paramsObj
                            )
                          }}
                          <btnComp
                            btnType="text"
                            @click="
                              remarkAdd('deviceRemarkEdit', scope.row, item.id)
                            "
                            v-permission="'设备备注编辑'"
                            >编辑</btnComp
                          >
                          <btnComp
                            btnType="text"
                            @click="del('remark', item.id,scope.row)"
                            v-permission="'设备备注删除'"
                            >删除</btnComp
                          >
                        </li>
                      </ul>
                      <div
                        class="btn"
                        @click="remarkAdd('deviceRemarkAdd', scope.row)"
                        v-permission="'设备备注新增'"
                      >
                        <i class="el-icon-plus"></i>
                        <span>设备备注</span>
                      </div>
                    </template>
                    <!-- 品控处理 -->
                    <template v-else>
                      <el-tabs v-if="showQc" v-model="remarkActiveName" type="card" style="margin-bottom:10px">
                      <el-tab-pane label="售后处理" name="saleRemark"
                        ></el-tab-pane
                      >
                      <el-tab-pane label="品控复核" name="qcRemark"
                        ></el-tab-pane
                      >
                    </el-tabs>
                    <div v-show="remarkActiveName === 'saleRemark'">
                      <ul>
                        <li v-for="item in scope.row.remarkData" :key="item.id">
                          {{ item.createdTime.slice(5) }}
                          {{ item.createdByRole || '客户' }}({{ item.createdByName }})：{{
                            handleRemark(
                              item.funcCode,
                              item.remark,
                              item.paramsObj
                            )
                          }}
                          <btnComp
                            btnType="text"
                            @click="
                              remarkAdd('deviceRemarkEdit', scope.row, item.id)
                            "
                            v-permission="'设备备注编辑'"
                            >编辑</btnComp
                          >
                          <btnComp
                            btnType="text"
                            @click="del('remark', item.id,scope.row)"
                            v-permission="'设备备注删除'"
                            >删除</btnComp
                          >
                        </li>
                      </ul>
                      <div
                        class="btn"
                        @click="remarkAdd('deviceRemarkAdd', scope.row)"
                        v-permission="'设备备注新增'"
                      >
                        <i class="el-icon-plus"></i>
                        <span>设备备注</span>
                      </div>
                    </div>
                    <div v-show="remarkActiveName === 'qcRemark'">
                      <ul>
                        <li v-for="item in scope.row.QCRemarkData" :key="item.id">
                          {{ item.createdTime.slice(5) }}
                          {{ item.createdByRole || '客户' }}({{ item.createdByName }})：{{
                            handleRemark(
                              item.funcCode,
                              item.remark,
                              item.paramsObj
                            )
                          }}
                          <btnComp
                            btnType="text"
                            @click="
                              remarkAdd('QCRemarkEdit', scope.row, item.id)
                            "
                            v-permission="'品控备注编辑'"
                            >编辑</btnComp
                          >
                          <btnComp
                            btnType="text"
                            @click="del('remark', item.id,scope.row)"
                            v-permission="'品控备注删除'"
                            >删除</btnComp
                          >
                        </li>
                      </ul>
                      <div
                        class="btn"
                        @click="remarkAdd('QCRemarkAdd', scope.row)"
                        v-permission="'品控备注新增'"
                      >
                        <i class="el-icon-plus"></i>
                        <span>品控备注</span>
                      </div>
                    </div>
                    </template>

                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template v-slot="scope">
                <btnComp
                  v-if="showQc"
                  style="margin-left:10px;width:74px"
                  size="mini"
                  @click="save(scope.row)"
                  v-permission="'品控保存'"
                  >保存</btnComp
                >
                <btnComp
                  v-else
                  style="margin-left:10px;width:74px"
                  size="mini"
                  @click="save(scope.row)"
                  v-permission="'鉴定记录保存'"
                  >保存</btnComp
                >
                <btnComp btnType="text" @click="imageUpdate(scope.row)"
                  >故障图</btnComp
                >
                <btnComp
                  btnType="text"
                  @click="del('device', scope.row.id)"
                  v-permission="'报障设备删除'"
                  v-if="Pinfo && !showQc && Pinfo.status !== 100 && Pinfo.status !== 1000"
                  >删除</btnComp
                >
                <btnComp
                  btnType="text"
                  @click="toogleExpand(scope.row, scope.$index)"
                  >{{
                    expands.includes(scope.row.id) ? '收起 ∧' : '展开 ∨'
                  }}</btnComp
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="curPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            background
            :total="total"
            :style="{ float: 'right', marginTop: '20px' }"
          ></el-pagination>
        </div>
      </div>
    </div>
    <!-- 序列号 -->
    <serialNumber
      ref="serialNumber"
      :isShow="serialShow"
      @close="close"
      :title="title"
      :info="info"
      :IndexInfo="IndexInfo"
      :showQc="showQc"
    />
    <!-- 快速处理 -->
    <fastHande
      :isShow="fastHandleShow"
      @close="needUpdateClose"
      :IndexInfo="IndexInfo"
      :fastType="fastType"
      :Pinfo="Pinfo"
    />
    <!-- 批量处理 -->
    <batchHandle
      :isShow="batchHandleShow"
      @close="close"
      :IndexInfo="IndexInfo"
      :selectIds="selectIds"
    />
    <!-- 转派 -->
    <transfer
      :isShow="transferShow"
      @close="needUpdateClose"
      :Pinfo="Pinfo"
      :IndexInfo="IndexInfo"
      :transferRole="transferRole"
    />
    <!-- 转交 -->
    <redeploy
      :isShow="redeployShow"
      @close="needUpdateClose"
      :IndexInfo="IndexInfo"
      :Pinfo="Pinfo"
    />
    <!-- 故障图 -->
    <imageDialog ref="imageDialog" :isShow="imageShow" @close="close" :info="info" />
    <!-- 新增设备备注 -->
    <dialogComp
      :title="remarkTitle"
      :isShow="remarkShow"
      :loading="remarkLoading"
      :modal="false"
      @close="remarkclose"
      @sure="remarkAddSure"
      width="640px"
    >
      <el-input
        v-model="remark"
        class="textarea"
        type="textarea"
        autosize
        placeholder="请输入备注"
      >
      </el-input>
    </dialogComp>
    <!-- 删除 -->
    <dialogComp
      title="提示"
      :isShow="delShow"
      :modal="false"
      @close="close"
      @sure="delSure"
    >
      <p>确认删除吗？</p>
    </dialogComp>
  </div>
</template>
<script>
import serialNumber from './dialog/serialNumber'
import fastHande from './dialog/fastHandle'
import batchHandle from './dialog/batchHandle'
import transfer from './dialog/transfer'
import redeploy from './dialog/redeploy'
import imageDialog from './dialog/imageDialog'
import {
  devicesInfoList,
  dictThree,
  devicesInfoUpdate,
  remarkList,
  remarkAdd,
  remarkDel,
  remarkEdit,
  handleLogAdd,
  devicesInfoDel,
  deviceFaulThree,
  qcTabList,
  qcTabSave,
  qcTabRemarkAdd,
  qcCheckAuditSure,
  batchCancleDiff
} from '@/api'
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'remote-handle',
  props: {
    Pinfo: {
      type: Object,
      default: () => {}
    },
    // 上门处理tab(true表示是上门处理的tab)
    showHandle: {
      type: Boolean,
      default: false
    },
    // 品控管理tab(true表示是品控管理tab)
    showQc: {
      type: Boolean,
      default: false
    }
  },
  components: {
    serialNumber,
    fastHande,
    batchHandle,
    redeploy,
    imageDialog,
    transfer
  },
  data () {
    return {
      remarkLoading: false,
      remarkActiveName: 'qcRemark',
      // 获取row的key值
      getRowKeys (row) {
        return row.id
      },
      // 要展开的行，数值的元素是row的key值
      expands: [],
      currentIndex: 0, // 当前展开行的索引
      fastType: 1, // 快速类型  快速处理1和快速响应2
      remarkType: '', // 用来区分是新增还是编辑
      transferRole: '', // 转派哪个角色
      selectIds: [],
      remarkShow: false,
      delShow: false,
      remark: '',
      causeAnalysisList: [],
      failCatList: [],
      deviceFaultList: [],
      treatmentMethodList: [],
      ownershipResponsibilityList: [],
      causeAnalysisArr: [],
      serialShow: false,
      fastHandleShow: false,
      batchHandleShow: false,
      redeployShow: false,
      transferShow: false,
      imageShow: false,
      curPage: 1,
      pageSize: 10,
      total: 0,
      title: '',
      tableData: [],
      info: {},
      isOpen: true // 是否展开(默认第一个展开)
    }
  },
  computed: {
    ...mapState(['drawerShow', 'IndexInfo']),
    allotDisabled () {
      return !(this.selectIds.length > 0)
    },
    // 是否是商家角色
    isStoreRole () {
      return this.$store.state.loginUserInfo.businessRole === 'store'
    },
    // 备注标题
    remarkTitle () {
      let str = ''
      switch (this.remarkType) {
        case 'deviceRemarkAdd':
          str = '添加设备备注'
          break
        case 'deviceRemarkEdit':
          str = '编辑设备备注'
          break
        case 'orderRemarkAdd':
          str = '添加工单日志'
          break
        case 'QCRemarkAdd':
          str = '添加品控备注'
          break
        case 'QCRemarkEdit':
          str = '编辑品控备注'
          break
      }
      return str
    }
  },
  watch: {
    drawerShow: {
      immediate: true,
      handler (val) {
        if (val) {
          this.getDeviceFaultList()
          this.getDevicesInfoList(true, true)
        } else {
          this.expands = []
          this.currentIndex = 0
          this.curPage = 1
          this.selectIds = []
          // 关闭侧弹窗 把所有弹窗show设置为false，解决按esc键，只关闭侧弹窗，没有关闭其他弹窗
          this.initDialog()
        }
      }
    }
  },
  mounted () {
    this.getSelectData(101015, 'failCatList')
    this.getSelectData(101018, 'causeAnalysisList')
    this.getSelectData(101021, 'treatmentMethodList')
    this.getSelectData(101024, 'ownershipResponsibilityList')
  },
  methods: {
    ...mapMutations(['SET_DRAWER_SHOW']),
    // 初始化每个弹窗
    initDialog () {
      this.remarkShow = false
      this.fastHandleShow = false
      this.redeployShow = false
      this.transferShow = false
      this.serialShow = false
      this.batchHandleShow = false
      this.imageShow = false
      this.delShow = false
      if (this.$refs.imageDialog) {
        this.$refs.imageDialog.zoomPicShow = false
      }
    },
    // 处理展开收起功能
    toogleExpand (row, index) {
      // 记录点击当前行的数据
      this.currentRow = row
      // 记录点击当前行的索引
      this.currentIndex = index
      // expands包含当前点击的id，就移除否则就添加，包含是已经展开，文字显示收起
      const flag = this.expands.includes(row.id) // false  点击展开  true  点击收起
      this.isOpen = flag
      if (flag) {
        this.expands = this.expands.filter(item => item !== row.id)
        // this.getDevicesInfoList(true)
      } else {
        this.expands = [] // 只保留一行展开
        this.expands.push(row.id)
      }
      this.getDevicesInfoList(true)

      // 展开就隐藏最新的备注
      const tableExpand = document.getElementsByClassName('table_expand')
      const divs = tableExpand[0].getElementsByClassName('el-table__row')
      const curLasestRemark = divs[index].nextSibling
      // 下一个元素必须是ul标签且类名是row_ul才隐藏
      if (curLasestRemark.className === 'row_ul') {
        curLasestRemark.style.display = 'none'
      }
      this.id = row.id
      // this.getRemarkList(row.id, index)
    },
    // 获取设备故障列表
    getDevicesInfoList (flag = false, once = false) {
      // flag用做标识 是否刷新插入的最新备注    once 是用做页面第一次加载  展开第一行
      const params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        orderId: this.IndexInfo.id
      }
      // 区分 品控管理tab的列表\远程处理tab列表
      const request = this.showQc ? qcTabList(params) : devicesInfoList(params)
      request.then(res => {
        this.tableData = res.data.list
        this.total = res.data.totalCount
        // 将序列号集合存到store中
        const serialNumArr = this.tableData.map(item => item.serialNum)
        this.$store.commit('SET_SERIAL_NUM', serialNumArr)
        // 页面一加载展开第一条，获取第一条的备注信息 隐藏最新备注
        if (once) {
          this.currentRow = this.tableData[0]
        }
        // 更新鉴定记录方式备注信息丢失
        this.getRemarkList(this.currentRow, this.currentIndex)
        flag && this.latestRemark()
        // 有bug  暂时不展开第一行
        // 页面第一次加载  展开第一行
        once && this.expands.push(this.tableData[0].id)
        // 因为第一次加载需要展开第一条   需要隐藏第一条的最新备注
        if (once) {
          this.$nextTick(() => {
            const tableExpand = document.getElementsByClassName(
              'table_expand'
            )
            const divs = tableExpand[0].getElementsByClassName(
              'el-table__row'
            )
            if (divs.length > 0) {
              const curLasestRemark = divs[0].nextSibling
              // 下一个元素必须是ul标签且类名是row_ul才隐藏
              if (curLasestRemark.className === 'row_ul') {
                curLasestRemark.style.display = 'none'
              }
            }
          })
        }
      })
    },
    // 处理表格每一行下面增加最新备注
    latestRemark () {
      this.$nextTick(() => {
        // 插入前先删除之前插入的
        var rowUl = document.getElementsByClassName('row_ul')
        for (let i = rowUl.length - 1; i >= 0; i--) {
          rowUl[i].parentNode.removeChild(rowUl[i])
        }
        const tableExpand = document.getElementsByClassName('table_expand')
        const divs = tableExpand[0].getElementsByClassName('el-table__row')
        for (var i = 0; i < divs.length; i++) {
          var div = divs[i]
          var logMessages = this.tableData[i].logMessages
          var divParent = div.parentNode
          let str = ''
          if (logMessages.length > 0) {
            logMessages.forEach(item => {
              str += `<li>${item.createdTime.slice(5)}  客服(${
                item.createdByName
              })：${item.remark}</li>`
            })
            const ele = document.createElement('ul')
            ele.className = 'row_ul'
            ele.style.position = 'relative'
            ele.style.width = 'calc(100vw - 370px)'
            ele.style.padding = '10px 0 10px 40px'
            ele.style.background = 'rgba(56, 91, 255, 0.04)'
            ele.style.borderBottom = '1px solid #EBEEF5'
            ele.innerHTML = str
            var next = div.nextSibling
            // 判断兄弟节点是否存在
            if (next) {
              // 存在则将新节点插入到div的下一个兄弟节点之前，即div之后
              divParent.insertBefore(ele, next)
            } else {
              // 不存在则直接添加到最后,appendChild默认添加到divParent的最后
              divParent.appendChild(ele)
            }
          }
        }
        if (divs[this.currentIndex]) {
          const curLasestRemark = divs[this.currentIndex].nextSibling
          // 下一个元素必须是ul标签且类名是row_ul才隐藏 并且是展开的时候隐藏
          if (curLasestRemark.className === 'row_ul' && !this.isOpen) {
            curLasestRemark.style.display = 'none'
          }
        }
      })
    },
    // 获取故障现象下拉
    getDeviceFaultList () {
      const params = {
        categoryId: this.IndexInfo.categoryId
      }
      deviceFaulThree(params).then(res => {
        this.deviceFaultList = res.data
      })
    },
    // 获取展开后  多个下拉列表 问题归类\原因分析\处理方式
    async getSelectData (code, data) {
      const params = {
        code
      }
      const res = await dictThree(params)
      this[data] = res.data || []
    },
    // 品控复查、审核、商家确认
    qcCheckAudit (status) {
      // 1：复查，2：审核，3：确认
      qcCheckAuditSure({ orderId: this.IndexInfo.id, status }).then(_ => {
        this.$message.success(status === 1 ? '复查通过' : status === 2 ? '审核成功' : '确认成功')
        // 更新详情  审核成功后按钮不能点击
        this.$emit('updateStatus')
        // 确认后关闭弹窗刷新列表
        if (status === 3) {
          this.SET_DRAWER_SHOW(false)
        }
      })
    },
    // 批量取消变更提醒
    cancleDiff () {
      const params = {
        ids: this.selectIds
      }
      batchCancleDiff(params).then(_ => {
        this.$message.success('批量取消成功')
        this.selectIds = []
        this.getDevicesInfoList()
      })
    },
    // 获取备注信息
    getRemarkList ({ id, orderEquipmentId }, index) {
      const params = {
        curPage: 1,
        pageSize: 100
      }
      //  远程备注信息
      remarkList({ ...params, recordId: this.showQc ? orderEquipmentId : id, moduleCode: 'OrderEquipmentItem' }).then(res => {
        res.data && this.$set(this.tableData[index], 'remarkData', res.data)
      })
      // 如果是远程处理tab的就不往下执行了
      if (!this.showQc) return
      //  品控管理备注品控
      remarkList({ ...params, recordId: id, moduleCode: 'OrderEquipmentExtendItem' }).then(res => {
        res.data && this.$set(this.tableData[index], 'QCRemarkData', res.data)
      })
    },
    // 序列号新增
    serialAdd () {
      this.serialShow = true
      this.title = 'add'
    },
    // 序列号编辑
    serialEdit (val) {
      this.info = val
      this.serialShow = true
      this.title = 'edit'
    },
    // 序列号删除清空
    serialDel (id) {
      this.$refs.serialNumber.serialDel(id)
    },
    fastHanlde (type) {
      this.fastType = type
      this.fastHandleShow = true
    },
    batchHandle () {
      this.batchHandleShow = true
    },
    redeploy () {
      this.redeployShow = true
    },
    // 转派
    handleTransfer (val) {
      this.transferShow = true
      this.transferRole = val
    },
    // 故障图上传
    imageUpdate (val) {
      this.info = val
      this.imageShow = true
    },
    // 封装方法，删掉数组中的null的数据
    filterArrNull (arr) {
      return arr.filter(item => item !== null && item !== '0' && item !== '')
    },
    // expand_left 设备故障\问题归类\原因分析\处理方式
    configFaultChange (index, val) {
      switch (val.length) {
        case 0:
          this.tableData[index].configFaultId = ''
          this.tableData[index].configFaultChildId = ''
          break
        case 1:
          this.tableData[index].configFaultId = val[0]
          this.tableData[index].configFaultChildId = ''
          break
        case 2:
          this.tableData[index].configFaultId = val[0]
          this.tableData[index].configFaultChildId = val[1]
          break
      }
    },
    // 封装方法鉴定记录选择事件
    handleChangeFn (index, val, type, one, two, three) {
      switch (val.length) {
        case 0:
          this.tableData[index][type] = ''
          this.tableData[index][one] = ''
          this.tableData[index][two] = ''
          this.tableData[index][three] = ''
          break
        case 1:
          this.tableData[index][type] = val[0]
          this.tableData[index][one] = ''
          this.tableData[index][two] = ''
          this.tableData[index][three] = ''
          break
        case 2:
          this.tableData[index][type] = val[0]
          this.tableData[index][one] = val[1]
          this.tableData[index][two] = ''
          this.tableData[index][three] = ''
          break
        case 3:
          this.tableData[index][type] = val[0]
          this.tableData[index][one] = val[1]
          this.tableData[index][two] = val[2]
          this.tableData[index][three] = ''
          break
        case 4:
          this.tableData[index][type] = val[0]
          this.tableData[index][one] = val[1]
          this.tableData[index][two] = val[2]
          this.tableData[index][three] = val[3]
          break
      }
    },
    failCatChange (index, val) {
      this.handleChangeFn(
        index,
        val,
        'failCat',
        'failCatOne',
        'failCatTwo',
        'failCatThree'
      )
    },
    treatmentMethodChange (index, val) {
      console.log(val)
      this.handleChangeFn(
        index,
        val,
        'treatmentMethod',
        'treatmentMethodOne',
        'treatmentMethodTwo',
        'treatmentMethodThree'
      )
    },
    ownershipResponsibilityChange (index, val) {
      // 品控的要区分字段
      this.showQc
        ? this.handleChangeFn(
          index,
          val,
          'qualityOwnershipResponsibility',
          'qualityOwnershipResponsibilityOne',
          'qualityOwnershipResponsibilityTwo',
          'qualityOwnershipResponsibilityThree'
        )
        : this.handleChangeFn(
          index,
          val,
          'ownershipResponsibility',
          'ownershipResponsibilityOne',
          'ownershipResponsibilityTwo',
          'ownershipResponsibilityThree'
        )
    },
    causeAnalysisChange (index, val) {
      // 品控的要区分字段
      this.showQc
        ? this.handleChangeFn(
          index,
          val,
          'qualityCauseAnalysis',
          'qualityCauseAnalysisOne',
          'qualityCauseAnalysisTwo',
          'qualityCauseAnalysisThree'
        ) : this.handleChangeFn(
          index,
          val,
          'causeAnalysis',
          'causeAnalysisOne',
          'causeAnalysisTwo',
          'causeAnalysisThree'
        )
    },
    poorUnpackingChange (index, val) {
      // 勾选后 原因禁止选择并置
      if (!val) {
        // 远程处理tab
        this.tableData[index].causeAnalysis = ''
        this.tableData[index].causeAnalysisOne = ''
        this.tableData[index].causeAnalysisTwo = ''
        this.tableData[index].causeAnalysisThree = ''
        // 品控tab
        this.tableData[index].qualityCauseAnalysis = ''
        this.tableData[index].qualityCauseAnalysisOne = ''
        this.tableData[index].qualityCauseAnalysisTwo = ''
        this.tableData[index].qualityCauseAnalysisThree = ''
        this.tableData[index].punish = false
        this.tableData[index].penaltyAmount = ''
      } else {
        // 开箱不良和处罚金额复选框联动
        this.tableData[index].punish = true
        this.tableData[index].penaltyAmount = 200
      }
    },
    // 是否处罚
    punishChange (index, val) {
      // 不勾选清空金额
      if (!val) {
        this.tableData[index].penaltyAmount = 0
      } else if (val && !this.tableData[index].penaltyAmount) {
        // 如果勾选了并且是空或者0  则自动填入200
        this.tableData[index].penaltyAmount = 200
      }
    },
    // 保存
    save (row) {
      const {
        id,
        orderId,
        orderEquipmentId,
        configFaultId,
        configFaultChildId,
        failCat,
        failCatOne,
        failCatTwo,
        failCatThree,
        treatmentMethod,
        treatmentMethodOne,
        treatmentMethodTwo,
        treatmentMethodThree,
        causeAnalysis,
        causeAnalysisOne,
        causeAnalysisTwo,
        causeAnalysisThree,
        ownershipResponsibility,
        ownershipResponsibilityOne,
        ownershipResponsibilityTwo,
        ownershipResponsibilityThree,
        poorUnpacking,
        qualityOwnershipResponsibility,
        qualityOwnershipResponsibilityOne,
        qualityOwnershipResponsibilityTwo,
        qualityOwnershipResponsibilityThree,
        qualityCauseAnalysis,
        qualityCauseAnalysisOne,
        qualityCauseAnalysisTwo,
        qualityCauseAnalysisThree,
        punish,
        penaltyAmount,
        num
      } = row
      // 区分传的参数（远程处理和品控管理）
      const params = this.showQc ? {
        id,
        orderId,
        orderEquipmentId,
        qualityOwnershipResponsibility,
        qualityOwnershipResponsibilityOne,
        qualityOwnershipResponsibilityTwo,
        qualityOwnershipResponsibilityThree,
        qualityCauseAnalysis,
        qualityCauseAnalysisOne,
        qualityCauseAnalysisTwo,
        qualityCauseAnalysisThree,
        punish,
        penaltyAmount,
        poorUnpacking
      } : {
        id,
        orderId,
        configFaultId,
        configFaultChildId,
        failCat,
        failCatOne,
        failCatTwo,
        failCatThree,
        treatmentMethod,
        treatmentMethodOne,
        treatmentMethodTwo,
        treatmentMethodThree,
        causeAnalysis,
        causeAnalysisOne,
        causeAnalysisTwo,
        causeAnalysisThree,
        ownershipResponsibility,
        ownershipResponsibilityOne,
        ownershipResponsibilityTwo,
        ownershipResponsibilityThree,
        poorUnpacking,
        num
      }
      // 远程处理保存
      const request = this.showQc ? qcTabSave(params) : devicesInfoUpdate(params)
      request.then(_ => {
        this.$message.success('保存成功')
        this.getDevicesInfoList()
      })
    },
    // 当前是清空序列号或者修改序列号时  备注需要显示新旧序列号
    handleRemark (funcCode, remark, paramsObj) {
      let remarkNew = ''
      if (funcCode === 'update_serial_num') {
        remarkNew = `${remark},原序列号${paramsObj.beforeSerialNum ||
          '-'},新序列号${paramsObj.afterSerialNum || '-'}`
      } else if (funcCode === 'empty_serial_num') {
        remarkNew = `${remark},原序列号${paramsObj.beforeSerialNum || '-'}`
      } else {
        remarkNew = remark
      }
      return remarkNew
    },
    // 新增设备备注和编辑  remarkType区分
    remarkAdd (type, val, remarkId) {
      this.remarkType = type
      this.remarkId = remarkId
      this.id = val && val.id
      this.info = val
      if (this.remarkType === 'deviceRemarkEdit') {
        this.remark = val.remarkData.find(item => item.id === remarkId).remark
      } else if (this.remarkType === 'QCRemarkEdit') {
        this.remark = val.QCRemarkData.find(item => item.id === remarkId).remark
      }
      this.remarkShow = true
    },
    remarkAddSure () {
      if (!this.remark) return
      let params = {}
      if (this.info) {
        const { id, orderEquipmentId } = this.info
        params = {
        // 品控页面 新增售后处理备注 需要orderEquipmentId  新增品控备注  需要传id
          recordId: this.showQc ? orderEquipmentId : id,
          remark: this.remark
        }
      }
      this.remarkLoading = true
      if (this.remarkType === 'deviceRemarkAdd') {
        remarkAdd(params).then(res => {
          this.remarkLoading = false
          this.$message.success('添加设备备注成功')
          this.getRemarkList(this.info, this.currentIndex)
          this.remarkShow = false
          this.remark = ''
          // 添加备注有快速响应的功能
          !this.Pinfo.quickResponse && this.$emit('updateStatus')
        })
      } else if (this.remarkType === 'deviceRemarkEdit' || this.remarkType === 'QCRemarkEdit') {
        // 设备备注和品控备注的编辑
        remarkEdit({ ...params, id: this.remarkId }).then(res => {
          this.remarkLoading = false
          this.$message.success('编辑备注成功')
          this.getRemarkList(this.info, this.currentIndex)
          this.remarkShow = false
          this.remark = ''
        })
      } else if (this.remarkType === 'orderRemarkAdd') {
        // 添加工单日志
        const params = {
          recordId: this.IndexInfo.id,
          remark: this.remark
        }
        handleLogAdd(params).then(res => {
          this.remarkLoading = false
          this.$message.success('添加工单日志成功')
          this.remarkShow = false
          this.remark = ''
        })
      } else if (this.remarkType === 'QCRemarkAdd') {
        // 品控备注新增
        qcTabRemarkAdd({ recordId: this.info.id, remark: this.remark }).then(res => {
          this.remarkLoading = false
          this.$message.success('添加品控备注成功')
          this.getRemarkList(this.info, this.currentIndex)
          this.remarkShow = false
          this.remark = ''
        })
      }
    },
    // 删除操作
    del (type, id, info) {
      // delType 用来区分哪种删除  有备注删除 还是列表删除
      this.info = info
      this.delType = type
      this.delType === 'device' ? (this.id = id) : (this.remarkId = id)
      this.delShow = true
    },
    delSure () {
      // 列表的删除
      if (this.delType === 'device') {
        devicesInfoDel({ id: this.id }).then(res => {
          this.$message.success('删除成功')
          this.close()
        })
      } else {
        // 备注删除
        remarkDel({ id: this.remarkId }).then(res => {
          this.$message.success('删除成功')
          this.getRemarkList(this.info, this.currentIndex)
          this.delShow = false
        })
      }
    },
    // 批量处理
    select (val) {
      this.selectIds = val.map(item => item.id)
    },
    selectAll (val) {
      this.selectIds = val.map(item => item.id)
    },
    handleSizeChange (val) {
      this.pageSize = val
      // 只要有开展的  当换页就模拟点击收起
      if (this.expands.length > 0) {
        this.toogleExpand(this.currentRow, this.currentIndex)
      }
      this.getDevicesInfoList(true)
    },
    handleCurrentChange (val) {
      this.curPage = val
      // 只要有开展的  当换页就模拟点击收起
      if (this.expands.length > 0) {
        this.toogleExpand(this.currentRow, this.currentIndex)
      }
      this.getDevicesInfoList(true)
    },
    remarkclose () {
      this.remark = ''
      this.remarkShow = false
    },
    needUpdateClose () {
      this.fastHandleShow = false
      this.redeployShow = false
      this.transferShow = false
      this.$emit('updateStatus')
    },
    close () {
      this.selectIds = []
      this.serialShow = false
      this.batchHandleShow = false
      this.imageShow = false
      this.delShow = false
      this.getDevicesInfoList()
    }
  }
}
</script>
<style>
.table_expand.el-table th > .cell {
  padding-right: 0;
}
.table_expand .el-table__expand-column .el-table__expand-icon {
  display: none;
}
</style>
<style lang="scss" scoped>
::v-deep .el-table__expanded-cell[class*='cell'] {
  padding: 15px;
}
.remote_handle_detail {
  .search_flex {
    justify-content: space-between;
  }
  .icon_common {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    .iconfont {
      font-size: 20px;
    }
    span {
      font-size: 12px;
      i {
        position: absolute;
        top: -10px;
        right: -6px;
        display: inline-block;
        width: 16px;
        height: 16px;
        line-height: 16px;
        border-radius: 50%;
        border: 1px solid #000;
        text-align: center;
        &.num2 {
          color: #fd5454;
          border-color: #fd5454;
        }
      }
    }
  }
  .remote_handle_detail_con {
    margin-top: 24px;
    .expand {
      display: flex;
      .expand_right {
        padding: 16px;
        background-color: rgba(56, 91, 255, 0.04);
        flex: 4;
        ul {
          max-height: 280px;
          overflow-y: scroll;
        }
        li {
          line-height: 35px;
        }
        .btn {
          width: 100%;
          height: 32px;
          line-height: 32px;
          border-radius: 4px;
          border: 1px solid rgba(0, 0, 0, 0.28);
          text-align: center;
          color: #385bff;
          cursor: pointer;
        }
      }
      .record {
        margin-left: 20px;
        // color: #385bff;
      }
    }
  }
}
::v-deep .textarea {
  .el-textarea__inner {
    min-height: 80px !important;
  }
}
</style>

<template>
  <dialogComp title="客户投诉" :isShow="isShow" :modal="false" @close="close" @sure="sure">
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="100px"
    >
      <selectFormComp
        label="投诉原因:"
        prop="reasonCode"
        placeholder="请选择投诉原因"
        :data="reasonList"
        :hval="formData.reasonCode"
        @change="reasonCodeChange"
      />
      <inputFormComp
        inputType="textarea"
        label="备注:"
        prop="remark"
        :hval="formData.remark"
        placeholder="请输入备注"
        @blur="remarkBlur"
      />
    </el-form>
  </dialogComp>
</template>

<script>
import { customerComplaint, dictList } from '@/api'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    Pinfo: {
      type: Object,
      default: () => {}
    },
    IndexInfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      reasonList: [],
      formData: {
        remark: '',
        reasonCode: null,
        reasonContent: ''
      },
      rules: {
        reasonCode: [
          { required: true, message: '请选择投诉原因', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
  },
  watch: {
    isShow (val) {
      val && this.getSelectData(101349)
    }
  },
  methods: {
    async getSelectData (parentCode) {
      const params = {
        curPage: 1,
        pageSize: 100,
        level: 2,
        parentCode
      }
      const res = await dictList(params)
      if (res.code === '1') {
        this.reasonList = res.data.list.map(item => ({
          label: item.name,
          value: item.code
        }))
      }
    },
    reasonCodeChange (val) {
      this.formData.reasonCode = val
      this.formData.reasonContent = this.reasonList.find(
        item => item.value === val
      ).label
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.formData.reasonContent = ''
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const params = {
            ...this.formData,
            complaint: true,
            id: this.IndexInfo.id
          }
          customerComplaint(params).then(res => {
            if (res.code === '1') {
              this.$message.success('投诉成功')
              this.close()
            }
          })
        }
      })
    }
  }
}
</script>

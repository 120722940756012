<template>
  <dialogComp
    :title="fastType === 2 ? '快速处理' : '快速响应'"
    :isShow="isShow"
    :modal="false"
    :loading="loading"
    @close="close"
    @sure="sure"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="100px"
    >
      <selectFormComp
        :label="fastType === 2 ? '处理方式:' : '响应方式'"
        prop="code"
        :placeholder="fastType === 2 ? '请选择处理方式' : '请选择响应方式'"
        :data="fastList"
        :hval="formData.code"
        @change="codeChange"
      />
      <inputFormComp
        label="备注:"
        prop="remark"
        inputType="textarea"
        :hval="formData.remark"
        @blur="remarkBlur"
        placeholder="请输入备注"
      />
    </el-form>
  </dialogComp>
</template>

<script>
import { dictList, fastHandleAndRes } from '@/api'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    // 快速类型  快速处理1和快速响应2
    fastType: {
      type: Number,
      default: 1
    },
    Pinfo: {
      type: Object,
      default: () => {}
    },
    IndexInfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false,
      fastList: [],
      responseList: [],
      formData: {
        code: '',
        remark: ''
      },
      rules: {
        code: [{ required: true, message: '请输入', trigger: 'change' }]
        // remark: [{ required: true, message: '请输入备注', trigger: 'blur' }]
      }
    }
  },
  watch: {
    isShow (val) {
      if (val) {
        this.fastType === 2
          ? this.getSelectData(101032)
          : this.getSelectData(101031)
      }
    }
  },
  methods: {
    async getSelectData (parentCode, data) {
      const params = {
        curPage: 1,
        pageSize: 100,
        level: 2,
        parentCode
      }
      const res = await dictList(params)
      if (res.code === '1') {
        this.fastList = res.data.list.map(item => ({
          label: item.name,
          value: item.code
        }))
        // 如果有值的话就显示出来（已处理的就禁用按钮）
        /*  if (this.fastType === 2 && this.Pinfo.quickHandle) {
          this.formData.code = this.Pinfo.quickHandle.code
          this.formData.remark = this.Pinfo.quickHandle.remark
        } else if (this.fastType === 1 && this.Pinfo.quickResponse) {
          this.formData.code = this.Pinfo.quickResponse.code
          this.formData.remark = this.Pinfo.quickResponse.remark
        } */
      }
    },
    codeChange (val) {
      this.formData.code = val
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const params = {
            ...this.formData,
            orderId: this.IndexInfo.id,
            type: this.fastType
          }
          this.loading = true
          fastHandleAndRes(params).then(res => {
            this.loading = false
            if (res.code === '1') {
              this.$message.success(
                `新增${this.fastType === 2 ? '处理方式' : '响应方式'}成功`
              )
              this.close()
            }
          })
        }
      })
    }
  }
}
</script>

<template>
  <div class="main_box">
    <div class="main_box_con">
      <div class="log_handle">
        <btnComp icon="el-icon-plus" @click="add" v-permission="'工单日志新增'"
          >工单日志</btnComp
        >
        <div v-loading="loading">
          <div class="item_c" v-for="(item, cindex) in logData" :key="cindex">
            <ul class="timeline">
              <li class="time">
                <!-- <div class="icon">
                <div class="circle"></div>
                <div class="line"  v-if="cindex !== logData.length-1"></div>
              </div> -->
                <ul class="content">
                  <li>{{ item.createdTime.slice(5) }}</li>
                  <li>{{ item.createdByRole }}({{ item.createdByName }})</li>
                  <li
                    :class="{
                      fastclass:
                        item.funcCode === 'response_prescription' ||
                        item.funcCode === 'handle_prescription'
                    }"
                  >
                    {{ handleTitile(item) }}
                  </li>
                  <li class="remark" v-if="item.remark" ref="remark">
                    备注：{{ item.remark }}
                  </li>
                  <!-- <li></li> -->
                </ul>
              </li>
            </ul>
            <!-- <div class="remark" v-if="item.remark">备注：{{ item.remark }}</div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 新增备注 -->
    <dialogComp
      title="添加工单日志"
      :isShow="remarkShow"
      :modal="false"
      @close="close"
      @sure="sure"
      width="640px"
    >
      <el-input
        v-model="remark"
        class="textarea"
        type="textarea"
        autosize
        placeholder="请输入处理日志"
      >
      </el-input>
    </dialogComp>
  </div>
</template>

<script>
import { handleLogList, handleLogAdd } from '@/api'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      remarkShow: false,
      loading: false,
      logData: [],
      remark: '',
      remarkMaxHeight: 20
    }
  },
  computed: {
    ...mapState(['IndexInfo', 'drawerShow'])
  },
  watch: {
    drawerShow: {
      handler (val) {
        if (val) {
          this.getList()
        } else {
          // 关闭侧弹窗 把所有弹窗show设置为false，解决按esc键，只关闭侧弹窗，没有关闭其他弹窗
          this.remarkShow = false
        }
      }
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      this.loading = true
      handleLogList({ orderId: this.IndexInfo.id }).then(res => {
        this.loading = false
        if (res.code === '1') {
          this.logData = res.data
        }
      })
    },
    handleTitile (item) {
      const { funcCode, titile, goalHandleName } = item
      if (
        funcCode === 'remote_deliver' ||
        funcCode === 'client_deliver' ||
        funcCode === 'dispatch_deliver' ||
        funcCode === 'store_deliver'
      ) {
        return `${titile.slice(0, -2)}(${goalHandleName})领取`
      } else {
        return titile
      }
    },
    add () {
      this.remarkShow = true
    },
    close () {
      this.remarkShow = false
      this.remark = ''
      this.getList()
    },
    sure () {
      const params = {
        recordId: this.IndexInfo.id,
        remark: this.remark
      }
      handleLogAdd(params).then(res => {
        if (res.code === '1') {
          this.$message.success('新增成功')
          this.close()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .textarea {
  .el-textarea__inner {
    min-height: 80px !important;
  }
}
// .remark {
//   margin-left: 50px;
// }
.timeline {
  display: flex;
  // margin-top: 10px;
  // height: 40px;
  .time {
    position: relative;
    display: flex;
    align-items: center;
    .icon {
      position: relative;
      .circle {
        position: absolute;
        left: 0;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #dfdfdf;
        &.el-icon-success {
          color: #0ebe7b;
          background-color: transparent;
          font-size: 18px;
          left: -4px;
        }
      }

      .line {
        position: absolute;
        top: 13px;
        left: 3px;
        width: 1px;
        height: 20px;
        background-color: #dfdfdf;
      }
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      margin-left: 20px;
      margin-top: 10px;
      li {
        margin-right: 30px;
        &.fastclass {
          color: #fff;
          height: 24px;
          line-height: 24px;
          padding: 0 5px;
          background-color: #2db051;
        }
      }
    }
  }
}
</style>

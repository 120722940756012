<template>
  <dialogComp title="二次上门" :isShow="isShow" :modal="false" @close="close" @sure="sure">
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="120px"
    >
      <paramsDict
        label="二次上门原因:"
        prop="reasonCode"
        :parentCode="101384"
         :hval="formData.reasonCode"
        placeholder="请选择二次上门原因"
        @change="reasonChange"
      />
      <selectFormComp
        label="首次上门单号:"
        prop="orderId"
        :data="orderIdList"
        placeholder="请选择首次上门单号"
        :hval="formData.orderId"
        @change="orderChange"
      >
      </selectFormComp>
      <inputFormComp
        inputType="textarea"
        label="备注:"
        prop="remark"
        :hval="formData.remark"
        @blur="remarkBlur"
        placeholder="请输入备注"
      />
    </el-form>
  </dialogComp>
</template>

<script>
import { secondScene, serviceVisitAboutOrder } from '@/api'
import paramsDict from '@/views/components/paramsDict'
export default {
  components: {
    paramsDict
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    IndexInfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      reasonList: [],
      orderIdList: [],
      formData: {
        orderId: '',
        reasonCode: '',
        reasonContent: '',
        remark: ''
      },
      rules: {
        orderId: [{ required: true, message: '请选择首次上门单号', trigger: 'change' }],
        reasonCode: [{ required: true, message: '请选择二次上门原因', trigger: 'change' }]
      }
    }
  },
  computed: {
  },
  watch: {
    isShow (val) {
      val && this.getAboutOrder()
    }
  },
  methods: {
    // 获取工单列表
    getAboutOrder () {
      serviceVisitAboutOrder({ orderCode: this.IndexInfo.orderCode }).then(
        res => {
          this.orderIdList = res.data.map(item => ({ value: item.orderId, label: item.code }))
        }
      )
    },
    reasonChange (val) {
      this.formData.reasonContent = val.name
      this.formData.reasonCode = val.code
    },
    orderChange (val) {
      this.formData.orderId = val
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const params = {
            ...this.formData,
            id: this.IndexInfo.id
          }
          secondScene(params).then(res => {
            this.$message.success('二次上门成功')
            this.close()
          })
        }
      })
    }
  }
}
</script>

<style></style>

<template>
  <dialogComp
    title="中止上门"
    :isShow="isShow"
    :modal="false"
    @close="close"
    @sure="sure"
  >
  <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="100px"
    >
    <selectFormComp
      label="上门费用:"
      prop="status"
      :data="statusList"
      :hval="formData.status"
      @change="statusChange"
      placeholder="请选择上门费用"
    />
     <selectFormComp
        label="中止原因:"
        prop="reasonCode"
        placeholder="请选择中止原因"
        :data="reasonList"
        :hval="formData.reasonCode"
        @change="reasonCodeChange"
      />
    <inputFormComp
      inputType="textarea"
      label="备注:"
      prop="remark"
      :hval="formData.remark"
      @blur="remarkBlur"
      placeholder="请输入备注"
    />
  </el-form>
  </dialogComp>
</template>

<script>
import { scenenOverAndAcceptance, dictList } from '@/api'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    visitId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    // 0：中止（没有产生费用），900：上门中止（已经出发产生费用）
      statusList: [
        { label: '上门费用不结算', value: 0 },
        { label: '已产生上门费用,需支付部分费用', value: 900 }
      ],
      reasonList: [],
      formData: {
        remark: '',
        status: null,
        reasonCode: null,
        reasonContent: ''
      },
      rules: {
        status: [{ required: true, message: '请选择上门费用', trigger: 'change' }],
        reasonCode: [
          { required: true, message: '请选择中止原因', trigger: 'change' }
        ]
      }
    }
  },
  watch: {
    isShow (val) {
      val && this.getSelectData(101329)
    }
  },
  methods: {
    async getSelectData (parentCode) {
      const params = {
        curPage: 1,
        pageSize: 100,
        level: 2,
        parentCode
      }
      const res = await dictList(params)
      if (res.code === '1') {
        this.reasonList = res.data.list.map(item => ({
          label: item.name,
          value: item.code
        }))
      }
    },
    statusChange (val) {
      this.formData.status = val
    },
    reasonCodeChange (val) {
      this.formData.reasonCode = val
      this.formData.reasonContent = this.reasonList.find(item => item.value === val).label
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const params = {
            id: this.visitId,
            ...this.formData
          }
          scenenOverAndAcceptance(params).then(res => {
            if (res.code === '1') {
              this.$message.success('中止上门成功')
              this.close()
            }
          })
        }
      })
    }
  }
}
</script>

<style></style>

<template>
  <dialogComp title="验收" :isShow="isShow" :modal="false" @close="close" @sure="sure">
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="100px"
    >
      <selectFormComp
        label="完成状态:"
        prop="status"
        :data="statusList"
        placeholder="请选择完成状态"
        :hval="formData.status"
        @change="statusChange"
      />
      <el-form-item label="转调度处理:">
        {{dispatchDeliverTime}}
      </el-form-item>
      <el-form-item label="实际到场:">
        {{arrivalTime}}
      </el-form-item>
      <el-form-item label="超时原因:" prop="reasonCode" v-if="isTimeOut">
        <el-select
          v-model="formData.reasonCode"
          placeholder="请选择超时原因"
          size="small"
          @change="reasonChange"
        >
          <el-option
            v-for="item in reasonList"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <inputFormComp
        inputType="textarea"
        label="备注:"
        prop="remark"
        :hval="formData.remark"
        @blur="remarkBlur"
        placeholder="请输入备注"
      />
    </el-form>
  </dialogComp>
</template>

<script>
import { dictList, scenenOverAndAcceptance } from '@/api'
import moment from 'moment'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    visitId: {
      type: String,
      default: ''
    },
    arrivalTime: {
      type: String,
      default: ''
    },
    dispatchDeliverTime: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      statusList: [
        { label: '已验收', value: 1000 },
        { label: '待更换', value: 910 },
        { label: '待二次上门', value: 920 }
      ],
      reasonList: [],
      formData: {
        status: '',
        reasonCode: '',
        reasonContent: ''
      },
      rules: {
        status: [{ required: true, message: '请选择完成状态', trigger: 'change' }],
        reasonCode: [{ required: true, message: '请选择超时原因', trigger: 'change' }]
      }
    }
  },
  computed: {
    // 调度接单与实际到场时间相差大于4个小时 显示超时原因  否不显示
    isTimeOut () {
      const date1 = moment(this.dispatchDeliverTime)
      const date2 = moment(this.arrivalTime)
      const date3 = date2.diff(date1, 'seconds')// 计算相差的秒数
      return date3 > 14400
    }
  },
  watch: {
    isShow (val) {
      val && this.getReasonList(101027)
    }
  },
  methods: {
    // 获取原因列表
    async getReasonList (parentCode) {
      const params = {
        curPage: 1,
        pageSize: 100,
        level: 2,
        parentCode
      }
      const res = await dictList(params)
      if (res.code === '1') {
        this.reasonList = res.data.list
      }
    },
    statusChange (val) {
      this.formData.status = val
    },
    reasonChange (val) {
      this.formData.reasonContent = this.reasonList.find(item => item.code === val).name
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const params = {
            ...this.formData,
            id: this.visitId
          }
          scenenOverAndAcceptance(params).then(res => {
            if (res.code === '1') {
              this.$message.success('验收成功')
              this.close()
            }
          })
        }
      })
    }
  }
}
</script>

<style></style>

<template>
  <div class="about_order_device">
    <div class="search_flex" style="margin-top:20px">
      <div class="search_left">
        <merchant :hval="storeName" @storeNameChange="storeNameChange" />
         <dateComp startPlaceholder="报修开始时间" endPlaceholder="报修结束时间" :hval="date" @change="dateChange" />
         <btnGroup  @search="search" @reset="reset" />
      </div>
    </div>
    <tableComp v-loading="loading"  :data="tableData" :col-configs="theadName">
      <el-table-column slot="code" label="远程单号" width="200">
        <template v-slot="scope">
          <span v-if="scope.row.code === Pinfo.code">
            {{ scope.row.code }}(当前工单)
          </span>
          <!-- 判断是否是已完结  已完结跳到历史工单页面   其他跳到跟进工单页面 -->
          <router-link
            target="_blank"
            v-else
            :to="{
              name: `${
                scope.row.status === 1000
                  ? 'work-order-history'
                  : 'work-order-follow'
              }`,
              query: { code: scope.row.code }
            }"
          >
            {{ scope.row.code }}
          </router-link>
          <div v-if="scope.row.firstOrder">
            上次报修:{{ scope.row.firstOrder }}
          </div>
          <div>({{ scope.row.orderStatusName }})</div>
        </template>
      </el-table-column>
      <el-table-column slot="orderCode" label="订单编号" width="230">
        <template v-slot="scope">
          <div>{{ scope.row.orderCode }}</div>
          <div>({{ scope.row.storeName }})</div>
        </template>
      </el-table-column>
    </tableComp>
  </div>
</template>
<script>
import merchant from '@/views/components/merchant'
import { aboutOrderToDevice } from '@/api'
export default {
  components: {
    merchant
  },
  props: {
    Pinfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false,
      storeName: '',
      storeId: '',
      date: [],
      startTime: '',
      endTime: '',
      tableData: [],
      theadName: [
        { prop: 'ownershipResponsibilityContent', label: '责任归属' },
        { prop: 'repairCode', label: '报修单号' },
        { slot: 'code' },
        { slot: 'orderCode' },
        { prop: 'categoryName', label: '设备分类' },
        { prop: 'serialNum', label: '序列号' },
        { prop: 'configFaultContent', label: '故障现象' },
        { prop: 'failCatContent', label: '问题归类' },
        { prop: 'treatmentMethodContent', label: '处理方式' }
      ]
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      const params = {
        customerId: this.Pinfo.customerId,
        storeId: this.storeId,
        startTime: this.startTime,
        endTime: this.endTime
      }
      this.loading = true
      aboutOrderToDevice(params).then(res => {
        this.loading = false
        this.tableData = res.data
      })
    },
    search () {
      this.getList()
    },
    reset () {
      this.storeId = ''
      this.storeName = ''
      this.startTime = ''
      this.endTime = ''
      this.date = []
      this.getList()
    },
    storeNameChange (val) {
      this.storeId = val.partnerId
      this.storeName = val.partnerName
    },
    dateChange (startTime, endTime) {
      this.startTime = startTime
      this.endTime = endTime
    }
  }
}
</script>

<template>
  <dialogComp
    title="取消工单"
    :isShow="isShow"
    :modal="false"
    @close="close"
    @sure="sure"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="100px"
    >
      <selectFormComp
        label="原因"
        prop="code"
        placeholder="请选择原因"
        :data="reasonList"
        :hval="formData.code"
        @change="codeChange"
      />
      <inputFormComp
        label="备注:"
        prop="remark"
        inputType="textarea"
        :hval="formData.remark"
        @blur="remarkBlur"
        placeholder="请输入备注"
      />
    </el-form>
  </dialogComp>
</template>

<script>
import { workOrderCancel, dictList } from '@/api'
import { mapActions } from 'vuex'
export default {
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    IndexInfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      reasonList: [],
      formData: {
        code: '',
        remark: ''
      },
      rules: {
        code: [{ required: true, message: '请选择取消原因', trigger: 'change' }]
      }
    }
  },
  watch: {
    isShow (val) {
      val && this.getSelectData(101198)
    }
  },
  methods: {
    ...mapActions('im', ['destroyGroupAction']),
    async getSelectData (parentCode) {
      const params = {
        curPage: 1,
        pageSize: 100,
        level: 2,
        parentCode
      }
      const res = await dictList(params)
      this.reasonList = res.data.list.map(item => ({
        label: item.name,
        value: item.code
      }))
    },
    codeChange (val) {
      this.formData.code = val
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const params = {
            ...this.formData,
            id: this.IndexInfo.id,
            status: 10001 // 数据状态，10001：取消，10002：删除
          }
          workOrderCancel(params).then(res => {
            this.$message.success('取消成功')
            this.close()
            // 解散群组
            this.destroyGroupAction(this.IndexInfo.id)
          })
        }
      })
    }
  }
}
</script>

<template>
  <div class="statistics">
    <div class="search_flex" style="margin-top:20px">
      <div class="search_left">
        <merchant :hval="storeName" @storeNameChange="storeNameChange" />
        <dateComp
          startPlaceholder="报修开始时间"
          endPlaceholder="报修结束时间"
          :hval="date"
          @change="dateChange"
        />
        <btnGroup @search="search" @reset="reset" />
      </div>
    </div>
    <ul class="echarts" v-loading="loading">
      <li>
        <div class="title">商家</div>
        <pieEcharts
          id1="analy_pie_box1"
          id2="analy_pie_echart1"
          :propData="storeData"
        />
      </li>
      <li>
        <div class="title">问题归类</div>
        <pieEcharts
          id1="analy_pie_box2"
          id2="analy_pie_echart2"
          :propData="problemData"
        />
      </li>
      <li>
        <div class="title">处理方式</div>
        <pieEcharts
          id1="analy_pie_box3"
          id2="analy_pie_echart3"
          :propData="categoryData"
        />
      </li>
      <li>
        <div class="title">责任归属</div>
        <pieEcharts
          id1="analy_pie_box4"
          id2="analy_pie_echart4"
          :propData="dutyData"
        />
      </li>
    </ul>
  </div>
</template>
<script>
import merchant from '@/views/components/merchant'
import pieEcharts from '@/views/data-center/components/pieEcharts'
import { aboutOrderToStatic } from '@/api'
export default {
  components: {
    pieEcharts,
    merchant
  },
  props: {
    Pinfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false,
      isShow: false,
      storeName: '',
      storeId: '',
      date: [],
      startTime: '',
      endTime: '',
      storeData: [],
      problemData: [],
      categoryData: [],
      dutyData: []
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      const params = {
        customerId: this.Pinfo.customerId,
        storeId: this.storeId,
        startTime: this.startTime,
        endTime: this.endTime,
        cycleType: 1,
        dimensional: 1
      }
      this.loading = true
      aboutOrderToStatic(params).then(res => {
        this.loading = false
        const { problem, stores, category, duty } = res.data
        this.storeData = stores
        this.problemData = problem
        this.categoryData = category
        this.dutyData = duty
      })
    },
    search () {
      this.getList()
    },
    reset () {
      this.storeId = ''
      this.storeName = ''
      this.startTime = ''
      this.endTime = ''
      this.date = []
      this.getList()
    },
    storeNameChange (val) {
      this.storeId = val.partnerId
      this.storeName = val.partnerName
    },
    dateChange (startTime, endTime) {
      this.startTime = startTime
      this.endTime = endTime
    }
  }
}
</script>
<style lang="scss" scoped>
.statistics {
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  li {
    width: 50%;
    height: 280px;
    margin-top: 20px;
    .title {
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      line-height: 40px;
      background-color: #eee;
    }
  }
}
</style>

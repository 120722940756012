<template>
  <dialogComp
    :title="titleD"
    :isShow="isShow"
    :modal="false"
    class="serialNumber"
    width="840px"
    @close="close"
    @sure="sure"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
    >
      <template v-if="title === 'add'">
        <div
          class="flex fault"
          v-for="(item, index) in formData.items"
          :key="index"
        >
          <serialNumber
            class="first"
            :data="serialNumList"
            :hval="item.serialNum"
            @change="serialNumChange(index, $event)"
            placeholder="可选择可输入序列号"
            style="margin-right:8px"
          />
          <span class="xing">*</span>
          <el-form-item>
            <cascader
              :data="deviceFaultList"
              :hval="deviceFaultArr"
              :propsParams="{ expandTrigger: 'click', value: 'id', label: 'appearance'}"
              @change="configFaultChange(index, $event)"
              placeholder="请选择故障现象"
            />
          </el-form-item>
          <inputFormComp inputType="textarea"  :hval="item.remark" placeholder="请输入备注" style="margin-left:8px;" @blur="remarkBlur(index, $event)"/>
          <p style="width:140px">
            <i
              class="btn add el-icon-circle-plus-outline"
              v-show="index === formData.items.length - 1"
              @click="add"
            ></i>
            <i
              class="btn del el-icon-remove-outline"
              v-show="index !== 0"
              @click="del(index)"
            ></i>
          </p>
        </div>
      </template>
      <template v-else>
        <inputFormComp label="原序列号:" disabled :hval="info&&info.serialNum" />
        <serialNumber
            label="修正序列号:"
            prop="serialNum"
            :data="serialNumList"
            :hval="formData.serialNum"
            @change="updateSerialChange"
            placeholder="可选择可输入序列号"
          />
      </template>
    </el-form>
  </dialogComp>
</template>

<script>
import serialNumber from '@/views/components/serialNumber'
import { deviceFaulThree, serialByOrderId, devicesSerialEdit, devicesInfoAdd, devicesSerialClear, validateSerialNum } from '@/api'
import { mapState } from 'vuex'
export default {
  components: { serialNumber },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    // 是否是品控管理tab
    showQc: {
      type: Boolean,
      default: false
    },
    IndexInfo: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: 'add'
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    titleD () {
      return this.title === 'add' ? '添加报障设备序列号' : '修正报障设备序列号'
    },
    // 从store中取  当前设备是否为故障
    ...mapState(['deviceFault'])
  },
  data () {
    return {
      deviceFaultArr: [],
      serialNumList: [],
      deviceFaultList: [],
      formData: {
        serialNum: '',
        items: [
          {
            serialNum: '',
            configFaultId: '',
            configFaultChildId: '',
            orderId: '',
            poorUnpacking: this.deviceFault,
            remark: ''
          }
        ]
      },
      rules: {
        // serialNum: [{ required: true, message: '请选择或输入修正序列号', trigger: 'change' }]
      }
    }
  },
  watch: {
    isShow (val) {
      if (val) {
        this.getList()
        this.formData.items[0].poorUnpacking = this.deviceFault
        this.formData.items[0].orderId = this.IndexInfo.id
      }
    }
  },
  methods: {
    // 获取故障现象下拉和序列号
    getList () {
      const params = {
        categoryId: this.IndexInfo.categoryId
      }
      deviceFaulThree(params).then(res => {
        if (res.code === '1') {
          this.deviceFaultList = res.data
        }
      })

      serialByOrderId({ orderId: this.IndexInfo.orderCode }).then(res => {
        if (res.code === 0) {
          if (Array.isArray(res.result)) {
            this.serialNumList = res.result
          }
        }
      })
    },
    // 新增序列号
    serialNumChange (index, val) {
      val = val.trim()
      this.formData.items[index].serialNum = val
      validateSerialNum({ code: val }).then(res => {
        if (res.data) {
          this.formData.items[index].serialNum = val
        } else {
          this.formData.items[index].serialNum = ''
          this.$message.error(`该序列号${val}存在未完成的工单`)
        }
      })
    },
    configFaultChange (index, val) {
      switch (val.length) {
        case 0:
          this.formData.items[index].configFaultId = ''
          this.formData.items[index].configFaultChildId = ''

          break
        case 1:
          this.formData.items[index].configFaultId = val[0]
          break
        case 2:
          this.formData.items[index].configFaultId = val[0]
          this.formData.items[index].configFaultChildId = val[1]
          break
      }
    },
    // 备注
    remarkBlur (index, val) {
      this.formData.items[index].remark = val
    },
    // 编辑序列号
    updateSerialChange (val) {
      this.formData.serialNum = val
    },
    add () {
      this.formData.items.push({
        serialNum: '',
        configFaultId: '',
        configFaultChildId: '',
        orderId: this.IndexInfo.id,
        poorUnpacking: this.deviceFault,
        remark: ''
      })
    },
    del (index) {
      this.formData.items.splice(index, 1)
    },
    close () {
      this.deviceFaultArr = []
      this.formData.items = [
        {
          serialNum: '',
          configFaultId: '',
          configFaultChildId: '',
          orderId: '',
          poorUnpacking: this.deviceFault,
          remark: ''
        }
      ]
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    // 清空序列号
    serialDel (id) {
      const params = {
        id
      }
      devicesSerialClear(params).then(res => {
        if (res.code === '1') {
          this.$message.success('序列号清空成功')
          this.$emit('close')
        }
      })
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          // const { items } = this.formData
          // if (items.some(item => item.configFaultId === '')) {
          //   this.$message.error('故障现象不能为空')
          //   return
          // }
          if (this.title === 'add') {
            devicesInfoAdd({ items: this.formData.items }).then(res => {
              if (res.code === '1') {
                this.$message.success('新增成功')
                this.close()
              }
            })
          } else {
            const params = {
              // 如果是品控管理编辑序列号 传orderEquipmentId
              id: this.showQc ? this.info.orderEquipmentId : this.info.id,
              serialNum: this.formData.serialNum
            }
            devicesSerialEdit(params).then(res => {
              if (res.code === '1') {
                this.$message.success('修改成功')
                this.close()
              }
            })
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .fault {
  align-items: center;
  justify-content: center;
  .el-form-item {
    width: 100%;
  }
  .btn {
    position: relative;
    top: -8px;
    left: 5px;
    width: 20px;
    font-size: 20px;
    cursor: pointer;
  }
  .xing {
    color: #f56c6c;
    position: relative;
    top: -15px;
    left: -2px;
    width: 10px;
  }
  // > div {
  //   flex: 2;
  //   .el-form-item__content {
  //     margin-left: 10px !important;
  //   }
  //   &.first {
  //     flex: 3;
  //     .el-form-item__content {
  //       margin-left: 115px !important;
  //     }
  //   }
  // }
}
</style>

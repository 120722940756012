<template>
  <dialogComp
    :title="dialogType === 'batch'?'批量指派回访客服':'指派回访客服'"
    :isShow="isShow"
    :modal="false"
    @close="close"
    @sure="sure"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="100px"
    >
      <el-form-item label="指派人员:" prop="visitServiceId">
        <handleUser
          placeholder="请选择指派人员"
          :hval="formData.visitServiceId"
          businessRole="service"
          @change="visitServiceIdChange"
        />
      </el-form-item>
    </el-form>
  </dialogComp>
</template>

<script>
import { batchDispatchVisit, assignVisitService } from '@/api'
import handleUser from '@/views/components/handleUser'
export default {
  components: { handleUser },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    IndexInfo: {
      type: Object,
      default: () => {}
    },
    selectIds: {
      type: Array,
      default: () => []
    },
    // 区别是批量指派batch还是单个指派single(工单详情中客服回访tab)
    dialogType: {
      type: String,
      default: 'batch'
    }
  },
  data () {
    return {
      formData: {
        visitServiceId: ''
      },
      rules: {
        visitServiceId: [
          { required: true, message: '请选择指派人员那', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    visitServiceIdChange (val) {
      this.formData.visitServiceId = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          // 批量回访
          if (this.dialogType === 'batch') {
            const params = {
              list: this.selectIds,
              visitServiceId: this.formData.visitServiceId
            }
            batchDispatchVisit(params).then(res => {
              if (res.code === '1') {
                this.$message.success('批量回访成功')
                this.close()
              }
            })
          } else {
            const params = {
              id: this.IndexInfo.id,
              visitServiceId: this.formData.visitServiceId
            }
            assignVisitService(params).then(res => {
              if (res.code === '1') {
                this.$message.success('指派回访成功')
                this.close()
              }
            })
          }
        }
      })
    }
  }
}
</script>

<template>
  <dialogComp
    title="群发派单"
    width="840px"
    top="9vh"
    :isShowFooter="false"
    :isShow="isShow"
    :modal="false"
    @close="close"
  >
    <div class="search_flex">
      <div class="search_left">
        <inputComp placeholder="服务站名称" :hval="name" @blur="nameBlur" />
        <inputComp placeholder="人员名称" :hval="personnel" @blur="personnelBlur" />
        <selectComp
          placeholder="合作类型"
          :data="cooperationModeList"
          :hval="cooperationMode"
          @change="cooperationModeChange"
        />
        <areaData @change="areaChange" :hval="area" />
        <btnGroup @search="search" @reset="reset" />
        <!-- <btnComp @click="send" v-if="status === 0">发单</btnComp> -->
      </div>
    </div>
      <!-- :isShowCheckBox="status === 0" -->
    <tableComp
      :data="tableData"
      :col-configs="theadName"
      :total="total"
      :currentPage="curPage"
      :pageSize="pageSize"
      @select="select"
      @selectAll="selectAll"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column slot="responseTime" label="抢单时间" width="170">
        <template v-slot="scope">
          {{ handleDate(scope.row.responseTime) }}
        </template>
      </el-table-column>
      <el-table-column slot="operate" label="操作" fixed="right">
        <template v-slot="scope">
          <!-- <btnComp btnType="text" @click="assign(scope.row)" v-if="status === 2"
            >派单</btnComp
          > -->
          <btnComp btnType="text" @click="assign(scope.row)" v-if="status === 0"
            >派单</btnComp
          >
        </template>
      </el-table-column>
    </tableComp>
  </dialogComp>
</template>

<script>
import areaData from '@/views/components/areaDataNew'
import constList from '@/mixin/constList'
import { sendOrderList, sendOrder, assignOrder } from '@/api'
import { formatDate, handleArea } from '@/utils/common'
export default {
  components: {
    areaData
  },
  mixins: [constList],
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    Pinfo: {
      type: Object,
      default: () => {}
    },
    visitId: {
      type: String,
      default: ''
    },
    status: {
      type: Number,
      default: 0
    } // 0：未派单，1：已派未接，2：已接单
  },
  data () {
    return {
      selectIds: [],
      curPage: 1,
      pageSize: 10,
      total: 0,
      name: '',
      personnel: '',
      cooperationMode: '',
      area: [],
      province: '',
      city: '',
      region: '',
      tableData: [],
      theadName: [
        { prop: 'statusName', label: '状态' },
        { prop: 'name', label: '主站' },
        { prop: 'masterStationName', label: '副站' },
        { prop: 'provinceCityRegion', label: '区域' },
        { prop: 'detailAddress', label: '地址' },
        { prop: 'orderNumber', label: '接单数' },
        { prop: 'cooperationModeName', label: '合作模式' },
        { slot: 'operate' }
      ]
    }
  },
  watch: {
    isShow (val) {
      if (val) {
        // 默认是自动筛选出对应的省市
        const { province, city } = this.Pinfo
        this.area = handleArea(province, city)
        this.province = province
        this.city = city
        this.getList()
      }
    }
  },
  computed: {
    allotDisabled () {
      return !(this.selectIds.length > 0)
    }
  },
  methods: {
    getList () {
      const params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        name: this.name,
        personnel: this.personnel,
        cooperationMode: this.cooperationMode,
        status: this.status,
        visitId: this.visitId,
        province: this.province,
        city: this.city,
        region: this.region
      }
      sendOrderList(params).then(res => {
        if (res.code === '1') {
          this.tableData = res.data.list
          this.total = res.data.totalCount
        }
      })
    },
    // 处理时间
    handleDate (date) {
      return date ? formatDate(date, 'YYYY-MM-DD HH:mm:ss') : '-'
    },
    close () {
      this.selectIds = []
      this.$emit('close')
    },
    nameBlur (val) {
      this.name = val
    },
    personnelBlur (val) {
      this.personnel = val
    },
    cooperationModeChange (val) {
      this.cooperationMode = val
    },
    areaChange (val) {
      const [a, b, c] = val
      this.area = val
      this.province = a || ''
      this.city = b || ''
      this.region = c || ''
    },
    search () {
      this.curPage = 1
      this.getList()
    },
    select (val) {
      this.selectIds = val.map(item => item.id)
    },
    selectAll (val) {
      this.selectIds = val.map(item => item.id)
    },
    // 发单
    send () {
      const params = {
        serviceStationIds: this.selectIds,
        visitOrderId: this.visitId
      }
      sendOrder(params).then(res => {
        if (res.code === '1') {
          this.$message.success('发单成功')
          this.close()
        }
      })
    },
    // 派单
    assign (row) {
      const params = {
        id: this.visitId,
        srviceStationId: row.id
      }
      assignOrder(params).then(res => {
        if (res.code === '1') {
          this.$message.success('派单成功')
          this.close()
        }
      })
    },
    reset () {
      this.curPage = 1
      this.name = ''
      this.personnel = ''
      this.cooperationMode = ''
      this.province = ''
      this.city = ''
      this.region = ''
      this.area = []
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>

<style></style>

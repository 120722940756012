<template>
  <dialogComp title="转交" :isShow="isShow" :modal="false" @close="close" @sure="sure">
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="120px"
    >
      <selectFormComp
        label="部门:"
        prop="businessRole"
        placeholder="请选择部门"
        :data="businessRoleListExceptFinance"
        :hval="formData.businessRole"
        @change="businessRoleChange"
      />
      <el-form-item label="转交人员:" prop="userId">
        <handleUser
          placeholder="请选择转交人员"
          :hval="formData.userId"
          :businessRole="formData.businessRole"
          :storeId="Pinfo && Pinfo.storeId"
          @change="userIdChange"
        />
      </el-form-item>
      <el-form-item
        label="是否中止上门:"
        prop="cancel"
        v-if="isShowCancelScence"
      >
        <el-radio-group v-model="formData.cancel" @change="cancelChange">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <selectFormComp
        v-if="
          formData.cancel === true &&
            this.formData.businessRole &&
            this.formData.businessRole !== 'dispatch'
        "
        label="中止原因:"
        prop="reasonCode"
        placeholder="请选择中止原因"
        :data="reasonList"
        :hval="formData.reasonCode"
        @change="reasonCodeChange"
      />
      <inputFormComp
        inputType="textarea"
        label="备注:"
        prop="remark"
        :hval="formData.remark"
        placeholder="请输入备注"
        @blur="remarkBlur"
      />
    </el-form>
  </dialogComp>
</template>

<script>
import handleUser from '@/views/components/handleUser'
import { businessRoleListExceptFinance } from '@/utils/constData'
import { workOrderDeliver, dictList } from '@/api'
export default {
  components: {
    handleUser
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    Pinfo: {
      type: Object,
      default: () => {}
    },
    IndexInfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      reasonList: [],
      formData: {
        businessRole: '',
        userId: '',
        remark: '',
        cancel: false,
        reasonCode: null,
        reasonContent: ''
      },
      rules: {
        businessRole: [
          { required: true, message: '请选择部门', trigger: 'change' }
        ],
        userId: [
          { required: true, message: '请选择转派人员', trigger: 'change' }
        ],
        cancel: [
          { required: true, message: '请选择是否中止上门', trigger: 'change' }
        ],
        reasonCode: [
          { required: true, message: '请选择中止原因', trigger: 'change' }
        ],
        remark: [
          { required: true, message: '请输入备注', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    businessRoleListExceptFinance () {
      return businessRoleListExceptFinance
    },
    // 是否显示中止上门 (必须有上门处理且选的部门不是调度)
    isShowCancelScence () {
      return (
        this.Pinfo &&
        this.Pinfo.haveVisit &&
        this.formData.businessRole &&
        this.formData.businessRole !== 'dispatch'
      )
    }
  },
  watch: {
    isShow (val) {
      if (val) {
        // 根据登录用户的角色来回显当先部门，如多个角色就不显示，只显示只有一个角色的
        const businessRoleArr = localStorage.getItem('businessRole').split(',')
        if (businessRoleArr.length === 1) {
          this.formData.businessRole = businessRoleArr[0]
        } else {
          this.formData.businessRole = ''
        }
        // 获取中止原因列表
        this.getSelectData(101329)
      }
    }
  },
  methods: {
    async getSelectData (parentCode) {
      const params = {
        curPage: 1,
        pageSize: 100,
        level: 2,
        parentCode
      }
      const res = await dictList(params)
      if (res.code === '1') {
        this.reasonList = res.data.list.map(item => ({
          label: item.name,
          value: item.code
        }))
      }
    },
    businessRoleChange (val) {
      this.formData.businessRole = val
      this.formData.userId = ''
      this.formData.cancel = false
    },
    userIdChange (val) {
      this.formData.userId = val
    },
    cancelChange () {
      this.formData.reasonCode = null
      this.formData.reasonContent = ''
    },
    reasonCodeChange (val) {
      this.formData.reasonCode = val
      this.formData.reasonContent = this.reasonList.find(
        item => item.value === val
      ).label
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.formData.reasonContent = ''
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const params = {
            ...this.formData,
            id: this.IndexInfo.id
          }
          workOrderDeliver(params).then(res => {
            if (res.code === '1') {
              this.$message.success('转交成功')
              this.close()
            }
          })
        }
      })
    }
  }
}
</script>

<template>
  <dialogComp
    :title="
      dialogType === 'add'
        ? '新增快递'
        : dialogType === 'edit'
        ? '快递编辑'
        : '待发货快递'
    "
    width="540px"
    :isShow="isShow"
    :modal="false"
    @close="close"
    @sure="sure"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="100px"
    >
      <radioFormComp
        label="快递类型:"
        prop="type"
        :radioList="typeList"
        :hval="formData.type"
        @change="typeChange"
      />
      <template v-if="dialogType !== 'addRemark'">
        <inputFormComp
          label="快递单号:"
          :prop="expressCodeProp"
          :hval="formData.expressCode"
          @input="expressCodeInput"
          placeholder="请输入快递单号"
        />
        <!-- <el-form-item label="快递公司:">
          {{ expressCompanyName }}
        </el-form-item> -->
        <paramsDict
          prop="expressCompany"
          label="快递公司:"
          :parentCode="101398"
          :optionsParams="{ value: 'configValue', label: 'name' }"
          :hval="formData.expressCompany"
          placeholder="请选择快递公司"
          @change="expressCompanyChange"
        />
        <inputFormComp
          label="寄件人手机号:"
          :prop="formData.expressCompany == 1 ? 'mobile' : ''"
          :hval="formData.mobile"
          @blur="mobileBlur"
          placeholder="请输入寄件人手机号"
        />
        <div
          style="color:#ff6700;position:relative;top:-10px;padding-left:100px"
        >
          请核实寄件人或收件人手机号
        </div>
        <inputFormComp
          label="收件人手机号:"
          :prop="formData.expressCompany == 1 ? 'recipientMobile' : ''"
          :hval="formData.recipientMobile"
          @blur="recipientMobileBlur"
          placeholder="请输入收件人手机号"
        />
      </template>
      <inputFormComp
        v-if="(dialogType === 'add' && !isStore) || dialogType !== 'add'"
        :disabled="isStore"
        inputType="textarea"
        label="平台备注:"
        prop="remark"
        :hval="formData.remark"
        @blur="remarkBlur"
        :placeholder="`${isStore ? '' : '请输入平台备注'}`"
      />
      <inputFormComp
        v-if="(dialogType === 'add' && isStore) || dialogType === 'edit'"
        :disabled="!isStore"
        inputType="textarea"
        label="商家备注:"
        prop="storeRemark"
        :hval="formData.storeRemark"
        @blur="storeRemarkBlur"
        :placeholder="`${!isStore ? '' : '请输入商家备注'}`"
      />
      <inputFormComp
        v-if="(dialogType === 'add' && isStore) || dialogType === 'edit'"
        disabled
        inputType="textarea"
        label="客户备注:"
        prop="customerRemark"
        :hval="formData.customerRemark"
      />
    </el-form>
  </dialogComp>
</template>

<script>
import paramsDict from '@/views/components/paramsDict'
import { expressAdd, expressEdit, expressCompanyByCode } from '@/api'
import { debounce, localGet } from '@/utils/common'
export default {
  components: {
    paramsDict
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    dialogType: {
      type: String,
      default: 'add'
    },
    info: {
      type: Object,
      default: () => {}
    },
    Pinfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    // const checkCode = (rule, val, cb) => {
    //   var Reg = /^[A-Za-z0-9]+$/
    //   if (Reg.test(val) || val === '') {
    //     return cb()
    //   }
    //   cb(new Error('只能输入字母和数字'))
    // }
    // const checkMobile = (rule, val, cb) => {
    //   var Reg = /^1[3456789]\d{9}$/
    //   if (Reg.test(val)) {
    //     return cb()
    //   }
    //   cb(new Error('手机号码格式不正确'))
    // }
    return {
      typeList: [
        { label: '商家寄给客户(客户收)', value: 1 },
        { label: '客户寄回商家(客户发)', value: 2 }
      ],
      expressCodeProp: 'expressCode',
      expressCompanyList: [],
      expressCompanyName: '',
      formData: {
        type: 1,
        expressCompany: null,
        expressCode: '',
        mobile: '',
        recipientMobile: '',
        remark: '',
        storeRemark: '',
        customerRemark: ''
      },
      rules: {
        type: [
          { required: true, message: '请选择快递类型', trigger: 'change' }
        ],
        expressCode: [
          { required: true, message: '请输入快递单号', trigger: 'blur' }
          // { validator: checkCode, trigger: 'blur' }
        ]
        // mobile: [
        //   { required: true, message: '请输入手机号', trigger: 'blur' },
        //   { validator: checkMobile, trigger: 'blur' }
        // ],
        // recipientMobile: [
        //   { required: true, message: '请输入手机号', trigger: 'blur' },
        //   { validator: checkMobile, trigger: 'blur' }
        // ]
      }
    }
  },
  computed: {
    // 是否是商家角色
    isStore () {
      if (localGet('loginUserInfo')) {
        return localGet('loginUserInfo').businessRole === 'store'
      } else {
        return false
      }
    }
  },
  watch: {
    isShow (val) {
      if (val) {
        if (this.dialogType === 'edit') {
          const {
            type,
            expressCompany,
            expressCode,
            remark,
            storeRemark,
            customerRemark,
            expressCompanyName
          } = this.info
          console.log(type)
          this.formData.expressCompany = expressCompany
          this.expressCompanyName = expressCompanyName
          this.formData.expressCode = expressCode
          this.expressCodeProp = expressCode ? 'expressCode' : ''
          this.typeChange(type)
          this.formData.remark = remark
          this.formData.storeRemark = storeRemark
          this.formData.customerRemark = customerRemark
        } else if (this.dialogType === 'add') {
          this.expressCodeProp = 'expressCode'
          // 新增快递弹窗
          // 默认是商家寄给客户  寄件人就是商家   收件人是客户
          const { contacterMobile, storeAddress } = this.Pinfo
          this.formData.mobile = storeAddress
            ? storeAddress.recipientMobile
            : ''
          this.formData.recipientMobile = contacterMobile
        }
      }
    }
  },
  methods: {
    typeChange (val) {
      this.formData.type = val
      // 默认是商家寄给客户  寄件人就是商家   收件人是客户
      // 客户寄给商家  寄件人就是客户   收件人是商家
      const {
        mobile,
        merchantMobile,
        recipientMobile,
        contacterMobile
      } = this.info
      if (mobile) {
        if (val === 1) {
          this.formData.mobile = recipientMobile
        } else {
          this.formData.mobile = mobile
        }
      } else {
        if (val === 1) {
          this.formData.mobile =
            merchantMobile ||
            (this.Pinfo &&
              this.Pinfo.storeAddress &&
              this.Pinfo.storeAddress.recipientMobile) ||
            ''
        } else {
          this.formData.mobile =
            contacterMobile || (this.Pinfo && this.Pinfo.contacterMobile) || ''
        }
      }
      if (recipientMobile) {
        if (val === 1) {
          this.formData.recipientMobile = mobile
        } else {
          this.formData.recipientMobile = recipientMobile
        }
      } else {
        if (val === 1) {
          this.formData.recipientMobile =
            contacterMobile || (this.Pinfo && this.Pinfo.contacterMobile) || ''
        } else {
          this.formData.recipientMobile =
            merchantMobile ||
            (this.Pinfo &&
              this.Pinfo.storeAddress &&
              this.Pinfo.storeAddress.recipientMobile) ||
            ''
        }
      }
    },
    expressCodeInput: debounce(function (val) {
      this.formData.expressCode = val
      // 输入快递单号字符大于6再请求 不然后端报错
      if (val.length > 6) {
        expressCompanyByCode({ expressCode: val }).then(res => {
          this.formData.expressCompany = res.data.type
          this.expressCompanyName = res.data.name
        })
      }
    }, 200),
    expressCompanyChange (val) {
      console.log(val)
      this.formData.expressCompany = val.configValue
    },
    mobileBlur (val) {
      this.formData.mobile = val
    },
    recipientMobileBlur (val) {
      this.formData.recipientMobile = val
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    storeRemarkBlur (val) {
      this.formData.storeRemark = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.expressCompanyName = ''
      this.formData = {
        type: 1,
        expressCompany: null,
        expressCode: '',
        mobile: '',
        recipientMobile: '',
        remark: '',
        storeRemark: '',
        customerRemark: ''
      }
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const {
            expressCompany,
            mobile,
            recipientMobile,
            expressCode
          } = this.formData
          if (
            this.expressCodeProp === 'expressCode' &&
            !expressCode &&
            this.dialogType !== 'addRemark'
          ) {
            this.$message.error('快递单号为必填项')
            return
          } else if (
            this.expressCodeProp === 'expressCode' &&
            expressCode &&
            this.dialogType !== 'addRemark'
          ) {
            const reg = /^[A-Za-z0-9]+$/
            if (!reg.test(expressCode)) {
              this.$message.error('快递单号只能输入字母和数字')
              return
            }
          }
          const reg = /^1[3456789]\d{9}$/
          let flag = false
          if (expressCompany === 1) {
            flag = mobile || recipientMobile
          } else {
            flag = true
          }
          if (!flag) {
            this.$message.error('当快递公司为顺丰时，手机号至少填一种')
            return
          }
          if (mobile && !reg.test(mobile)) {
            this.$message.error('手机号码格式不正确')
            return
          }
          if (recipientMobile && !reg.test(recipientMobile)) {
            this.$message.error('手机号码格式不正确')
            return
          }
          const params = {
            ...this.formData,
            orderId:
              this.dialogType === 'edit' ? this.info.orderId : this.Pinfo.id
          }
          if (this.dialogType === 'add') {
            // 新增时 写死status:1 待发货
            expressAdd({ ...params, status: 1 }).then(res => {
              if (res.code === '1') {
                this.$message.success('新增成功')
                this.close()
              }
            })
          } else if (this.dialogType === 'edit') {
            // 快递编辑
            expressEdit({ id: this.info.id, ...params }).then(res => {
              if (res.code === '1') {
                this.$message.success('编辑成功')
                this.close()
              }
            })
          } else {
            // 新增待发货备注 写死status:0 待收件
            const { orderId, remark, type, storeRemark } = params
            expressAdd({ orderId, remark, storeRemark, type, status: 0 }).then(
              res => {
                if (res.code === '1') {
                  this.$message.success('新增成功')
                  this.close()
                }
              }
            )
          }
        }
      })
    }
  }
}
</script>

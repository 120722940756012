<template>
  <div class="main_box">
    <div class="main_box_con">
      <el-tabs style="padding-left:0" v-model="activeName" type="card">
        <el-tab-pane label="报修工单" name="order">
        </el-tab-pane>
        <el-tab-pane label="报修设备" name="device">
        </el-tab-pane>
        <el-tab-pane label="报修统计" name="statistics">
        </el-tab-pane>
      </el-tabs>
      <component :is="activeName" :Pinfo="Pinfo"></component>
    </div>
  </div>
</template>
<script>
import order from './tabs/order'
import device from './tabs/device'
import statistics from './tabs/statistics'
export default {
  components: {
    order,
    device,
    statistics
  },
  props: {
    Pinfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      activeName: 'order'
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<template>
  <dialogComp
    class="image_dialog"
    title="故障图"
    width="760px"
    top="10vh"
    :isShow="isShow"
    :modal="false"
    @close="close"
    :isShowFooter="false"
  >
    <div class="main_img">
      <div class="arrow el-icon-arrow-left" @click="pre"></div>
      <video ref="video" v-if="currentSuffix.toLowerCase() === 'mp4'" :src="currentUrl" width="100%" height="100%" controls>
        <source  type="video/mp4">
        您的浏览器不支持Video标签。
      </video>
      <img v-else :src="currentUrl" alt="" width="100%" height="100%" />
      <div class="arrow el-icon-arrow-right" @click="next"></div>
    </div>
    <div class="scroll_box" style="width:100%">
      <ul class="img_list" v-permission="'故障图上传'">
      <li
        v-for="(item, index) in imageListNew"
        :key="index"
        :class="{ active: currentIndex === index }"
      >
        <video v-if="item.suffix.toLowerCase() === 'mp4'" width="100%" height="100%" controls>
          <source :src="item.url" type="video/mp4">
          您的浏览器不支持Video标签。
        </video>
        <img v-else :src="item.url" alt="" width="100%" height="100%" />
        <div class="del_icon" @click="delPic(index, item.catelog,item.id)">
          <i class="el-icon-delete-solid"></i>
        </div>
        <div v-if="item.suffix.toLowerCase() !== 'mp4'" class="del_icon zoom_icon" @click="zoomPic(item.url)">
          <i class="el-icon-zoom-in"></i>
        </div>
      </li>
      <li style="cursor:pointer">
        <el-upload
          class="upload"
          multiple
          :show-file-list="false"
          action=""
          :http-request="uploadpic"
          :before-upload="beforeUpload"
        >
          <i class="el-icon-circle-plus"></i>
        </el-upload>
      </li>
    </ul>
    </div>
    <el-dialog title="预览" :visible.sync="zoomPicShow" :modal="false">
      <img width="100%" :src="zoomPicUrl" alt="">
    </el-dialog>
  </dialogComp>
</template>

<script>
import { client, catelogImg, catelogImgTest, catelogVideoTest, catelogVideo, ossURL, uuid } from '@/utils/alioss'
import { faultImgAdd, faultImgDel } from '@/api'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      zoomPicShow: false,
      zoomPicUrl: '',
      list: [],
      limit: 10,
      dialogImageUrl: '',
      dialogVisible: false,
      currentIndex: 0,
      imageListNew: [],
      currentUrl: '', // 当前点击的url
      currentSuffix: '', // 当前点击的文件后缀
      // 是否是线上环境  存储不同的目录
      isProduction: process.env.VUE_APP_CURRENTMODE === 'prod'
    }
  },
  watch: {
    isShow (val) {
      if (val) {
        this.imageListNew = this.info.attachmentList.map(item => ({ url: ossURL + item.filePath, catelog: item.filePath, id: item.id, suffix: item.suffix }))
      }
    },
    imageListNew: {
      deep: true,
      handler (val) {
        // 防止选中的 和 当前要删的是同一个报错
        if (val.length === this.currentIndex) {
          this.currentIndex = 0
        }
        this.currentUrl = val.length > 0 ? val[this.currentIndex].url : ''
        this.currentSuffix = val.length > 0 ? val[this.currentIndex].suffix : ''
      }
    }
  },
  mounted () {
  },
  methods: {
    // 上传前
    beforeUpload (file) {
      const index = file.name.lastIndexOf('.')
      const suffix = file.name.substring(index + 1)
      this.suffix = suffix // 后缀名
      const reg = /(jpg|jpeg|png|mp4)/i
      if (!reg.test(suffix)) {
        this.$message.error('请上传jpg或png格式的图片、mp4格式视频')
        return false
      }
      if (file.size > 50 * 1024 * 1024) {
        this.$message.error('文件大小不能超过50M')
        return false
      }
    },
    // 上传图片
    uploadpic (file) {
      this.save(file)
    },
    // 放大图片
    zoomPic (url) {
      this.zoomPicShow = true
      this.zoomPicUrl = url
    },
    // 删除图片 先删除数据库  在删除oss存储
    delPic (index, catelog, id) {
      faultImgDel({ id }).then(res => {
        if (res.code === '1') {
          client
            .delete(catelog)
            .then(res => {
              this.imageListNew.splice(index, 1)
            })
            .catch(err => {
              console.log(err)
            })
        }
      })
    },
    // 保存
    save (file) {
      const fileName = uuid() + '.' + this.suffix // 生成文件名（file.file.name）
      // 判断存储到哪个目录下 不同的环境存到不同的文件夹中
      let catelog = ''
      if (this.isProduction) {
        if (this.suffix.toLowerCase() === 'mp4') {
          catelog = catelogVideo
        } else {
          catelog = catelogImg
        }
      } else {
        if (this.suffix.toLowerCase() === 'mp4') {
          catelog = catelogVideoTest
        } else {
          catelog = catelogImgTest
        }
      }
      const params = {
        recordId: this.info.id,
        filePath: catelog + fileName,
        suffix: this.suffix,
        moduleCode: 'OrderEquipmentItem'
      }
      faultImgAdd(params).then(res => {
        if (res.code === '1') {
          client
            .put(catelog + fileName, file.file)
            .then(res2 => {
              this.imageListNew.push({
                id: res.data.id,
                url: res2.url,
                catelog: catelog + fileName,
                suffix: this.suffix
              })
            })
            .catch(err => {
              console.log(err)
            })
        }
      })
    },
    pre () {
      if (this.imageListNew.length === 0) return
      this.currentIndex--
      this.currentIndex = this.currentIndex <= 0 ? 0 : this.currentIndex
      this.currentUrl = this.imageListNew[this.currentIndex].url
      this.currentSuffix = this.imageListNew[this.currentIndex].suffix
    },
    next () {
      if (this.imageListNew.length === 0) return
      this.currentIndex++
      const length = this.imageListNew.length
      this.currentIndex =
      this.currentIndex >= length - 1 ? length - 1 : this.currentIndex
      this.currentUrl = this.imageListNew[this.currentIndex].url
      this.currentSuffix = this.imageListNew[this.currentIndex].suffix
    },
    close () {
      this.currentIndex = 0
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.image_dialog {
  .el-dialog__body {
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      .main_img {
        position: relative;
        display: flex;
        width: 550px;
        height: 390px;
        background: #ffffff;
        border-radius: 8px;
        border: 1px solid #e9e9e9;
        text-align: center;
        padding: 18px;
        .arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 44px;
          height: 44px;
          line-height: 44px;
          border-radius: 50%;
          background: rgba(0, 0, 0, 0.18);
          font-size: 25px;
          font-weight: bold;
          cursor: pointer;
          color: #fff;
          &.el-icon-arrow-left {
            left: -68px;
          }
          &.el-icon-arrow-right {
            right: -68px;
          }
        }
      }
      .img_list {
        display: flex;
        margin-top: 8px;
        overflow-x: auto;
        li {
          flex-shrink: 0;
          position: relative;
          width: 80px;
          height: 80px;
          line-height: 80px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #e9e9e9;
          padding: 10px;
          margin-right: 8px;
          text-align: center;
          &.active {
            border-color: #385bff;
          }
          .el-icon-circle-plus {
            font-size: 30px;
            color: rgba(51, 51, 51, 0.3);
          }
          .del_icon {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 22px;
            height: 22px;
            background: rgba(51, 51, 51, 0.4);
            border-radius: 4px 0px 3px 0px;
            cursor: pointer;
            &.zoom_icon {
              left: 0;
              .el-icon-zoom-in {
                position: absolute;
                left: 0;
                font-size: 20px;
                color: #fff;
              }
            }
          }

          .el-icon-delete-solid {
            color: #fff;
            position: absolute;
            right: 3px;
            bottom: 3px;
          }
        }
        .upload {
          .el-upload--picture-card {
            width: 102px;
            height: 102px;
            line-height: 102px;
          }
        }
      }
    }
  }
}
</style>

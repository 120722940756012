<template>
  <div class="main_box">
    <div class="handle">
      <div class="handle_left">
        <!-- 发单功能暂时隐藏 -->
        <!-- <btnComp :disabled="info.status !== 20 && info.status !== 30" @click="sendOrder(0)">发单</btnComp>   -->
        <btnComp @click="toService" :disabled="info.orderStatus === 1000" v-permission="'转客服'">转客服</btnComp>
        <btnComp btnType="danger" :disabled="info.status < 10 || info.status > 70" @click="over" v-permission="'中止上门'">中止上门</btnComp>
        <btnComp :disabled="info.status >=900" @click="changeServiceStation" v-permission="'改派服务站'">改派服务站</btnComp>
        <div class="order_num">
          发单<span>{{ info.outCount }}</span
          >/抢单<span>{{ info.robCount }}</span>
        </div>
      </div>
      <div class="handle_right">
        <!-- <btnComp round  :disabled="info.status !== 20" @click="sendOrder(2)">派单</btnComp> -->
        <btnComp round :disabled="info.status !== 20" @click="sendOrder(0)" v-permission="'派单'">派单</btnComp>
        <btnComp v-if="!btn1Flag" round disabled>派单</btnComp>
        <i class="iconfont iconjiantouyou first" :style="{color:info.status >= 30?'#385bff':'#dfdfdf'}"></i>
        <btnComp round :disabled="info.status !== 40" @click="updateStatus(50)" v-permission="'出发'">出发</btnComp>
        <btnComp v-if="!btn2Flag" round disabled>出发</btnComp>
        <i class="iconfont iconjiantouyou" :style="{color:info.status >= 50?'#385bff':'#dfdfdf'}"></i>
        <btnComp round :disabled="info.status !== 50" @click="updateStatus(60)" v-permission="'到场'">到场</btnComp>
        <btnComp v-if="!btn3Flag" round disabled>到场</btnComp>
        <i class="iconfont iconjiantouyou" :style="{color:info.status >= 60?'#385bff':'#dfdfdf'}"></i>
        <btnComp round :disabled="info.status !== 60" @click="updateStatus(70)" v-permission="'申请验收'">申请验收</btnComp>
        <btnComp v-if="!btn4Flag" round disabled>申请验收</btnComp>
        <i class="iconfont iconjiantouyou" :style="{color:info.status >= 70?'#385bff':'#dfdfdf'}"></i>
        <btnComp round :disabled="info.status !== 70" @click="updateStatus(1000)" v-permission="'验收'">验收</btnComp>
        <btnComp v-if="!btn5Flag" round disabled>验收</btnComp>
      </div>
    </div>
    <div class="scene_info bgc">
      <div class="title scene_info_title">
        <span>上门信息</span>
        <!-- <btnComp @click="sceneInfoUpdate" style="margin-left:20px"  :disabled="info.status > 20">更新信息</btnComp> -->
      </div>
      <div class="scene_info_con">
        <el-form
          :model="formData"
          :rules="rules"
          ref="formData"
          label-width="85px"
        >
          <div class="item flex">
            <el-form-item label="上门区域" prop="area">
              <areaData :hval="formData.area" disabled @change="areaChange" />
            </el-form-item>
            <inputFormComp
              label="上门地址"
              prop="detailAddress"
              :hval="formData.detailAddress"
              disabled
              @blur="detailAddressBlur"
              placeholder="请输入详细地址"
            />
            <el-form-item label="预约时间" prop="appointmentVisitTime">
              <el-date-picker
                v-model="formData.appointmentVisitTime"
                type="datetime"
                size="small"
                placeholder="选择预约时间"
                :disabled="info.status > 20"
                @change="appointmentVisitTimeChange"
                @focus="appointmentVisitTimeFocus"
                @blur="appointmentVisitTimeBlur"
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="item flex">
            <inputFormComp
              label="上门身份"
              prop="visitIdentity"
              :hval="formData.visitIdentity"
              @blur="visitIdentityBlur"
              :disabled="info.status > 20"
            />
            <inputFormComp
              class="contact_people"
              label="用户现场联系人"
              prop="repairContacter"
              :hval="formData.repairContacter"
              @blur="repairContacterBlur"
              placeholder="请输入用户现场联系人"
              disabled
            />
            <inputFormComp
              label="联系方式"
              prop="contacterMobile"
              :hval="formData.contacterMobile"
              @blur="contacterMobileBlur"
              placeholder="请输入联系方式"
              disabled
            />
          </div>
          <div class="line"></div>
          <ul class="server_info">
            <li>
              <span>技术支持：</span>
              <span>{{ info.dispatchName }} {{ info.dispatchMobile }}</span>
            </li>
            <li>
              <span>服务站名称：</span>
              <span>{{ info.serviceStationName }}</span>
            </li>
            <li>
              <span>服务站负责人：</span>
              <span
                >{{ info.responsibleName }} {{ info.responsibleMobile }}</span
              >
            </li>
          </ul>
          <div>
            <span>上门工程师：</span>
            <span>{{ info.visitName }} {{ info.visitMobile }}</span>
          </div>
          <inputFormComp
            class="desc"
            inputType="textarea"
            label="故障描述"
            prop="remark"
            :hval="formData.remark"
            @blur="remarkBlur"
            placeholder="请输入故障描述"
          />
        </el-form>
      </div>
    </div>
    <div class="scene_requ bgc">
      <div class="title">
        上门要求
      </div>
      <ul class="scene_requ_con">
        <li>
           <div class="ctitle">上门前注意事项
              <i v-if="$route.path !== '/crm'" class="el-icon-circle-plus-outline" @click="noteAdd(1)"></i>
           </div>
           <div class="item" v-for="(item,index) in noteBeforeList" :key="item.id">
              <div style="margin-right:10px">{{index+1}}</div>
              <el-input size="small" clearable placeholder="请输入注意事项" v-model="item.note" @change="noteChange(1,item.id,$event)"></el-input>
              <i class="el-icon-error" @click="noteDel(item.id)"></i>
           </div>
        </li>
        <li>
           <div class="ctitle">上门服务注意事项
              <i v-if="$route.path !== '/crm'"  class="el-icon-circle-plus-outline" @click="noteAdd(2)"></i>
           </div>
           <div class="item" v-for="(item,index)  in noteMiddleList" :key="item.id">
             <div style="margin-right:10px">{{index+1}}</div>
              <el-input size="small" clearable placeholder="请输入注意事项" v-model="item.note" @change="noteChange(2,item.id,$event)"></el-input>
              <i class="el-icon-error" @click="noteDel(item.id)"></i>
           </div>
        </li>
         <li>
           <div class="ctitle">服务完结注意事项
              <i v-if="$route.path !== '/crm'"  class="el-icon-circle-plus-outline" @click="noteAdd(3)"></i>
           </div>
           <div class="item" v-for="(item,index)  in noteAfterList" :key="item.id">
             <div style="margin-right:10px">{{index+1}}</div>
              <el-input size="small" clearable placeholder="请输入注意事项" v-model="item.note" @change="noteChange(3,item.id,$event)"></el-input>
              <i class="el-icon-error" @click="noteDel(item.id)"></i>
           </div>
        </li>
      </ul>
    </div>
    <div class="devices bgc">
      <div class="title">
        报障设备
      </div>
      <div class="devices_con">
        <remoteHandle :showHandle="true" />
      </div>
    </div>
    <div class="scene_time bgc">
      <div class="title">
        上门时效
      </div>
      <ul class="scene_time_con">
         <el-timeline>
            <el-timeline-item
              v-for="(item,index) in info.timeMap"
              :key="index"
              :timestamp="item.value">
               {{item.key}}
            </el-timeline-item>
          </el-timeline>
    </ul>
    </div>
    <div class="price bgc">
      <div class="title price_title">
        <span>上门费用{{info.visitCostStatusName !== null?`(${info.visitCostStatusName})`:''}}</span>
        <btnComp icon="el-icon-plus" :disabled="info.visitCostStatus > 1" @click="add('add')" v-permission="'费用新增'">费用</btnComp>
      </div>
      <div class="price_con">
        <tableComp
          :showSummary="true"
          :data="tableData"
          :col-configs="theadName"
        >
          <el-table-column slot="remark" label="备注">
            <template v-slot="scope">
              {{ scope.row.remark }}
            </template>
            <!-- {{scope.row.remark}} -->
          </el-table-column>
          <el-table-column slot="operate" label="操作" fixed="right">
            <template v-slot="scope">
              <btnComp btnType="text" @click="add('edit', scope.row)" :disabled="info.visitCostStatus > 1" v-permission="'费用编辑'"
                >编辑</btnComp
              >
              <btnComp btnType="text" @click="del(scope.row)" :disabled="info.visitCostStatus > 1" v-permission="'费用删除'"
                >删除</btnComp
              >
            </template>
          </el-table-column>
        </tableComp>
      </div>
    </div>
    <!-- 增加费用 -->
    <addPrice
      :isShow="addShow"
      @close="priceClose"
      :title="title"
      :visitId="info.id"
      :info="row"
    />
    <!-- 派单 -->
    <sendOrder  :isShow="sendShow" @close="close" :Pinfo="Pinfo" :visitId="info.id" :status="status" />
    <!-- 验收 -->
    <acceptance  :isShow="acceptanceShow" @close="close" :visitId="info.id" :arrivalTime="info.arrivalTime" :dispatchDeliverTime="info.dispatchDeliverTime" />
    <!-- 中止和上门中止 -->
    <sceneOver  :isShow="overShow" @close="close" :visitId="info.id" />
     <!-- 删除 -->
    <dialogComp title="提示" :isShow="delShow" :modal="false" @close="priceClose" @sure="sureDel">
      <p>确定删除吗？</p>
    </dialogComp>
     <!-- 出发、到场、申请验收 -->
    <updateStatus  :isShow="updateStatusShow" :updateStatusN="updateStatusN" :visitId="info.id" @close="close" />
    <!-- 新增注意事项 -->
    <addNote :isShow="noteShow" :noteType="noteType" :visitId="info.id" @close="noteClose" />
  </div>
</template>

<script>
import areaData from '@/views/components/areaDataNew'
import remoteHandle from '../remote-handle'
import addPrice from './dialog/addPrice'
import sendOrder from './dialog/sendOrder'
import acceptance from './dialog/acceptance'
import sceneOver from './dialog/sceneOver'
import updateStatus from './dialog/updateStatus'
import addNote from './dialog/addNote'
import {
  sceneDetail,
  sceneReset,
  scenePriceList,
  scenePriceDel,
  sceneEdit,
  serviceNoteList,
  serviceNoteEdit,
  serviceNoteDel,
  sceneUpdateTime,
  workOrderTransfer
} from '@/api'
import { formatDate, handleArea } from '@/utils/common'
import { btnPermission } from '@/utils/directive'
import { mapState } from 'vuex'
export default {
  components: {
    areaData,
    remoteHandle,
    addPrice,
    sendOrder,
    acceptance,
    sceneOver,
    updateStatus,
    addNote
  },
  props: {
    Pinfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      addShow: false,
      delShow: false,
      sendShow: false,
      acceptanceShow: false,
      overShow: false,
      updateStatusShow: false,
      noteShow: false,
      updateStatusN: 50, // 50：出发，60：到达，70：申请验收，1000：已验收
      status: 0, // 0：未派单，1：已派未接，2：已接单
      noteType: 1, // 上门注意事项类别：1：上门前（默认），2：上门服务，3：服务完结
      title: '',
      info: {},
      row: {},
      formData: {
        appointmentVisitTime: '', // 预约时间单独处理
        area: [],
        province: '',
        city: '',
        region: '',
        detailAddress: '',
        repairContacter: '',
        contacterMobile: '',
        visitIdentity: '',
        remark: ''
      },
      rules: {
        detailAddress: [{ required: true, message: '请输入上门地址', trigger: 'blur' }],
        // visitIdentity: [{ required: true, message: '请输入上门身份', trigger: 'blur' }],
        repairContacter: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        contacterMobile: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
        appointmentVisitTime: [{ required: true, message: '请选择预约时间', trigger: 'change' }]
      },
      noteBeforeList: [],
      noteMiddleList: [],
      noteAfterList: [],
      tableData: [],
      theadName: [
        { slot: 'operate' },
        { prop: 'typeName', label: '费用类别' },
        { prop: 'title', label: '费用名称' },
        { prop: 'price', label: '单价(元)' },
        { prop: 'num', label: '数量(件)' },
        { prop: 'subtotal', label: '小计(元)' },
        { slot: 'remark' }
      ]
    }
  },
  computed: {
    ...mapState(['IndexInfo', 'drawerShow', 'menuList']),
    // 来判断是否有 派单 出发 到场 申请验收 验收 按钮权限，如果没有权限也要显示出来  只是不能点击
    btn1Flag () {
      return btnPermission(this.menuList, this.$route.path, '派单')
    },
    btn2Flag () {
      return btnPermission(this.menuList, this.$route.path, '出发')
    },
    btn3Flag () {
      return btnPermission(this.menuList, this.$route.path, '到场')
    },
    btn4Flag () {
      return btnPermission(this.menuList, this.$route.path, '申请验收')
    },
    btn5Flag () {
      return btnPermission(this.menuList, this.$route.path, '验收')
    }
  },
  watch: {
    drawerShow: {
      handler (val) {
        if (val) {
          this.getDetailInfo()
        } else {
          // 关闭侧弹窗 把所有弹窗show设置为false，解决按esc键，只关闭侧弹窗，没有关闭其他弹窗
          this.initDialog()
        }
      }
    }
  },
  mounted () {
    this.getDetailInfo()
  },
  methods: {
    // 初始化每个弹窗
    initDialog () {
      this.addShow = false
      this.delShow = false
      this.sendShow = false
      this.acceptanceShow = false
      this.overShow = false
      this.updateStatusShow = false
      this.noteShow = false
    },
    async getDetailInfo () {
      const res = await sceneDetail({ orderId: this.IndexInfo.id })
      // 如果上门工单没有  后台返回false
      if (res.data) {
        this.info = res.data
        const {
          province,
          city,
          region,
          detailAddress,
          appointmentVisitTime,
          repairContacter,
          contacterMobile,
          visitIdentity,
          remark
        } = this.info
        this.formData.area = handleArea(province, city, region)
        const [a, b, c] = this.formData.area
        this.formData.province = a || ''
        this.formData.city = b || ''
        this.formData.region = c || ''
        this.formData.detailAddress = detailAddress
        this.formData.appointmentVisitTime = appointmentVisitTime
        this.formData.repairContacter = repairContacter
        this.formData.contacterMobile = contacterMobile
        this.formData.visitIdentity = visitIdentity
        this.formData.remark = remark
        // 根据id获取费用列表
        this.getScenePriceList(this.info.id)
        // 根据id获取注意事项列表
        this.getServiceNoteList(this.info.id)
      }
    },
    // 获取费用列表
    async getScenePriceList (visitId) {
      const res2 = await scenePriceList({ visitId })
      if (res2.code === '1') {
        this.tableData = res2.data
      }
    },
    // 处理注意事项列表数据  根据类型区分
    handleNoteData (data, type) {
      return data.filter(item => item.type === type).map(item => ({ id: item.id, note: item.note }))
    },
    // 获取上门要求注意事项列表
    async getServiceNoteList (visitId) {
      const res = await serviceNoteList({ visitId })
      this.noteBeforeList = this.handleNoteData(res.data, 1)
      this.noteMiddleList = this.handleNoteData(res.data, 2)
      this.noteAfterList = this.handleNoteData(res.data, 3)
    },
    // 处理时间
    handleDate (date) {
      if (date) {
        return formatDate(date, 'YYYY-MM-DD HH:mm:ss')
      }
    },
    // 暂时不支持修改
    areaChange (val) {
      // this.formData.area = val
      // const [a, b, c] = val
      // this.formData.province = a || ''
      // this.formData.city = b || ''
      // this.formData.region = c || ''
    },
    detailAddressBlur (val) {
      // this.formData.detailAddress = val
    },
    repairContacterBlur (val) {
      // this.formData.repairContacter = val
    },
    contacterMobileBlur (val) {
      // this.formData.contacterMobile = val
    },
    visitIdentityBlur (val) {
      this.formData.visitIdentity = val
      this.sceneInfoUpdate()
    },
    remarkBlur (val) {
      this.formData.remark = val
      this.sceneInfoUpdate()
    },
    appointmentVisitTimeChange (val) {
      this.formData.appointmentVisitTime = val
      this.updateVisitTime()
    },
    // 优化  只有时间为空时  那自动输入当前时间
    appointmentVisitTimeFocus (val) {
      if (!this.formData.appointmentVisitTime) {
        this.formData.appointmentVisitTime = new Date()
        this.currentTime = this.formData.appointmentVisitTime
      }
    },
    appointmentVisitTimeBlur () {
      // 失去焦点时  只有赋值的当前一致  就请求接口
      if (this.currentTime === this.formData.appointmentVisitTime) {
        this.updateVisitTime()
      }
    },
    // 上门信息更新
    sceneInfoUpdate () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const params = {
            id: this.info.id,
            orderId: this.IndexInfo.id,
            visitIdentity: this.formData.visitIdentity,
            remark: this.formData.remark
          }
          sceneEdit(params).then(res => {
            this.$message.success('更新成功')
            this.getDetailInfo()
          })
        }
      })
    },
    // 更新预约时间
    updateVisitTime () {
      const params = {
        id: this.info.id,
        appointmentVisitTime: this.formData.appointmentVisitTime
      }
      sceneUpdateTime(params).then(res => {
        this.$message.success('更新时间成功')
        this.getDetailInfo()
      })
    },
    // 中止、上门中止
    over () {
      this.overShow = true
    },
    // 改派服务站
    changeServiceStation () {
      sceneReset({ id: this.info.id }).then(res => {
        this.$message.success('改派成功')
        this.getDetailInfo()
      })
    },
    // 发单
    sendOrder (status) {
      // 0：未派单，1：已派未接，2：已接单
      if (!this.formData.appointmentVisitTime) {
        this.$message.error('预约时间不能为空')
      } else {
        this.status = status
        this.sendShow = true
      }
    },
    // 转客服
    toService () {
      const params = {
        id: this.IndexInfo.id,
        businessRole: 'service'
      }
      workOrderTransfer(params).then(res => {
        this.$message.success('转客服成功')
        this.$emit('updateStatus')
      })
    },
    add (val, row) {
      this.title = val
      if (val === 'edit') {
        this.row = row
      }
      this.addShow = true
    },
    del (row) {
      this.row = row
      this.delShow = true
    },
    // 出发、到场、申请验收、验收
    updateStatus (status) {
      this.updateStatusN = status
      status === 1000 ? this.acceptanceShow = true : this.updateStatusShow = true
    },
    // 删除
    sureDel () {
      scenePriceDel({ id: this.row.id }).then(res => {
        this.$message.success('删除成功')
        this.priceClose()
      })
    },
    // 上门要求-新增
    noteAdd (type) {
      this.noteType = type
      this.noteShow = true
    },
    // 上门要求-编辑
    noteChange (type, id, note) {
      const params = {
        id,
        type,
        note,
        visitId: this.info.id
      }
      serviceNoteEdit(params).then(res => {
        this.$message.success('修改成功')
        this.getServiceNoteList(this.info.id)
      })
    },
    // 上门要求-删除
    noteDel (id) {
      serviceNoteDel({ id }).then(res => {
        this.$message.success('删除成功')
        this.getServiceNoteList(this.info.id)
      })
    },
    priceClose () {
      this.addShow = false
      this.delShow = false
      this.getScenePriceList(this.info.id)
    },
    noteClose () {
      this.noteShow = false
      this.getServiceNoteList(this.info.id)
    },
    close () {
      this.acceptanceShow = false
      this.sendShow = false
      this.overShow = false
      this.updateStatusShow = false
      this.getDetailInfo()
    }
  }
}
</script>

<style lang="scss" scoped>
.bgc {
  background-color: #fff;
  margin-bottom: 24px;
}
.title {
  height: 55px;
  line-height: 55px;
  padding: 0 32px;
  border-bottom: 1px solid #e9e9e9;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
}
.handle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .handle_left {
    display: flex;
    align-items: center;
    .order_num {
      margin-left: 16px;
      color: #999999;
      span {
        &:nth-child(1) {
          color: #000;
          font-weight: 500;
        }
        &:nth-child(2) {
          color: #ff7126;
          font-weight: 500;
        }
      }
    }
  }
  .handle_right {
    .iconfont {
      margin: 0 17px;
      color: #dfdfdf;
      &.first {
        color: #385bff;
      }
    }
  }
}
.scene_info {
  .scene_info_title {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    // padding-right: 75px;
  }
  .scene_info_con {
    padding: 32px;
    .item {
      ::v-deep .el-form-item {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-right: 40px;
        &.contact_people {
          .el-form-item__label {
            width: 126px !important;
          }
        }
        .el-form-item__label {
          line-height: 30px;
          text-align: left;
        }
        .el-form-item__content {
          margin-left: 0 !important;
        }
        .el-date-editor {
          width: 100%;
        }
      }
    }
    .server_info {
      display: flex;
      margin: 16px;
      li {
        flex: 1;
      }
    }
    .desc {
      width: 50%;
      margin-top: 16px;
    }
  }
  .line {
    width: 100%;
    height: 1px;
    background: #e8e8e8;
    margin-top: 10px;
    margin-bottom: 32px;
  }
}
.price {
  &.bgc {
    margin-bottom: 0;
  }
  .price_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .price_con {
    padding: 0 32px 32px 32px;
  }
}
.scene_time {
  .scene_time_con {
    padding-left: 50px;
    padding-top: 25px;
  }
}
.scene_requ {
  .scene_requ_con {
    display: flex;
    padding: 32px;
    li {
      flex: 1;
      padding-right: 40px;
      .ctitle {
        margin-bottom: 18px;
        i {
          font-size: 18px;
          color: #385BFF;
          cursor: pointer;
        }
      }
      .item {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        i {
          margin-left: 10px;
          color: #ADADAD;
          font-size: 18px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>

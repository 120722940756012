<template>
  <dialogComp title="完成转回访" :isShow="isShow" :modal="false" @close="close" @sure="sure">
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="100px"
    >
      <el-form-item label="建单时间:">
        {{createdTime}} (时间差:{{diffTime}})
      </el-form-item>
      <el-form-item label="超时原因:" prop="code">
        <el-select
          v-model="formData.code"
          placeholder="请选择超时原因"
          size="small"
        >
          <el-option
            v-for="item in reasonList"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <inputFormComp
        inputType="textarea"
        label="备注:"
        prop="remark"
        :hval="formData.remark"
        @blur="remarkBlur"
        placeholder="请输入备注"
      />
    </el-form>
  </dialogComp>
</template>

<script>
import { dictList, completeReturnVisit } from '@/api'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    // 建单时间
    createdTime: {
      type: String,
      default: ''
    },
    // 建单时间
    orderId: {
      type: String,
      default: ''
    },
    // 时间差
    diffTime: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      reasonList: [],
      formData: {
        code: '',
        remark: ''
      },
      rules: {
        code: [{ required: true, message: '请选择超时原因', trigger: 'change' }]
      }
    }
  },
  watch: {
    isShow (val) {
      val && this.getReasonList(101223)
    }
  },
  methods: {
    // 获取原因列表
    async getReasonList (parentCode) {
      const params = {
        curPage: 1,
        pageSize: 100,
        level: 2,
        parentCode
      }
      const res = await dictList(params)
      if (res.code === '1') {
        this.reasonList = res.data.list
      }
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const params = {
            ...this.formData,
            id: this.orderId
          }
          completeReturnVisit(params).then(res => {
            if (res.code === '1') {
              this.$message.success('转回访成功')
              this.close()
            }
          })
        }
      })
    }
  }
}
</script>

<style></style>

<template>
  <tableComp v-loading="loading" :data="tableData" :col-configs="theadName">
    <el-table-column slot="code" label="远程单号" width="220">
      <template v-slot="scope">
        <span v-if="scope.row.code === Pinfo.code">
          {{ scope.row.code }}(当前工单)
        </span>
        <!-- 判断是否是已完结  已完结跳到历史工单页面   其他跳到跟进工单页面 -->
        <router-link
          target="_blank"
          v-else
          :to="{
            name: `${
              scope.row.status === 1000
                ? 'work-order-history'
                : 'work-order-follow'
            }`,
            query: { code: scope.row.code }
          }"
        >
          {{ scope.row.code }}
        </router-link>
        <div v-if="scope.row.firstOrder">上次报修:{{scope.row.firstOrder}}</div>
        <div>({{scope.row.statusName }})</div>
      </template>
    </el-table-column>
    <el-table-column slot="orderCode" label="订单编号" width="230">
      <template v-slot="scope">
        <div>{{scope.row.orderCode }}</div>
        <div>({{scope.row.storeName }})</div>
      </template>
    </el-table-column>
    <el-table-column slot="visitService" label="回访客服/回访时间">
      <template v-slot="scope">
        <div v-if="!scope.row.visitServiceName && !scope.row.visitServiceTime">
          -
        </div>
        <div v-else>
          <div v-if="scope.row.visitServiceName">{{scope.row.visitServiceName}}</div>
          <div v-if="scope.row.visitServiceTime">{{scope.row.visitServiceTime }}</div>
        </div>

      </template>
    </el-table-column>
    <el-table-column slot="handlerInfo" label="处理人" width="140">
      <template v-slot="scope">
        <ul>
          <li v-if="scope.row.serviceName">客服:{{ scope.row.serviceName }}</li>
          <li v-if="scope.row.remoteName">售后:{{ scope.row.remoteName }}</li>
          <li v-if="scope.row.storeHandleName">
            商家:{{ scope.row.storeHandleName }}
          </li>
          <li v-if="scope.row.dispatchName">
            调度:{{ scope.row.dispatchName }}
          </li>
        </ul>
      </template>
    </el-table-column>
  </tableComp>
</template>
<script>
import { serviceVisitAboutOrder } from '@/api'
export default {
  props: {
    Pinfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false,
      tableData: [],
      theadName: [
        { slot: 'visitService' },
        { prop: 'repairCode', label: '报修单号' },
        { slot: 'code' },
        { slot: 'orderCode' },
        { prop: 'num', label: '设备台数' },
        { prop: 'visitNum', label: '上门次数' },
        { slot: 'handlerInfo' }
      ]
    }
  },
  mounted () {
    this.getAboutOrder()
  },
  methods: {
    getAboutOrder () {
      this.loading = true
      serviceVisitAboutOrder({ customerId: this.Pinfo.customerId }).then(
        res => {
          this.loading = false
          this.tableData = res.data
        }
      )
    }
  }
}
</script>
